// date-fns
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ErrorBoundary } from 'components/error-boundary';
import {
  AppNotificationsProvider,
  AppPageProvider,
  AppThemeProvider,
  AuthProvider,
  AxiosInterceptorsProvider,
  MetaProvider,
  TranslateProvider,
} from 'contexts';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// store
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRoutes } from 'router';
import { appPersistor, appStore } from 'store';
import 'styles/app.global.scss';

function App() {
  return (
    <ErrorBoundary>
      <AppNotificationsProvider>
        <Provider store={appStore}>
          <PersistGate persistor={appPersistor} loading={null}>
            <MetaProvider>
              <AxiosInterceptorsProvider>
                <AppThemeProvider>
                  <TranslateProvider>
                    <AuthProvider>
                      <AppPageProvider>
                        <DndProvider backend={HTML5Backend}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <CssBaseline />
                            <AppRoutes />
                          </LocalizationProvider>
                        </DndProvider>
                      </AppPageProvider>
                    </AuthProvider>
                  </TranslateProvider>
                </AppThemeProvider>
              </AxiosInterceptorsProvider>
            </MetaProvider>
          </PersistGate>
        </Provider>
      </AppNotificationsProvider>
    </ErrorBoundary>
  );
}

export default App;
