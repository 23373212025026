import { useAppSelector } from 'hooks/redux';
import { useCallback } from 'react';
import { selectAccountPercentSymbol } from 'store/auth';

export const useFormatPercent = () => {
  const symbol = useAppSelector(selectAccountPercentSymbol);
  return useCallback(
    (v: string | number | null | undefined) => {
      return v ? `${v}${symbol}` : v;
    },
    [symbol],
  );
};
