import { createAsyncThunk } from '@reduxjs/toolkit';
import { Label, ServiceLabels } from 'services/labels';
import { AppAsyncThunkConfig } from 'store/index';
import { selectLabelsAll } from 'store/labels/selectors';
import { parseErrorData } from 'utils/service';

export const actionLabelsSetLanguage = createAsyncThunk<
  { languageID: string; data: Label[] },
  { languageID: string | null },
  AppAsyncThunkConfig
>(`LABELS/actionLabelsSet`, async ({ languageID }, { getState }) => {
  try {
    let requestLanguageID = languageID;

    if (!requestLanguageID) {
      const results = await ServiceLabels.getDefaultLanguageID();
      requestLanguageID = results.data;
    }

    const allLabels = selectLabelsAll(getState());
    let labelsList = allLabels[requestLanguageID];

    if (!labelsList) {
      const {
        data: { value: responseAllLabels },
      } = await ServiceLabels.getLabels(requestLanguageID);
      labelsList = responseAllLabels;
    }

    return { languageID: requestLanguageID, data: labelsList };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
