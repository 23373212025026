import LogoutIcon from '@mui/icons-material/Logout';
import { Divider, ListItem, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { AppLogo } from 'components/app-logo';
import { useAppDispatch, useMountedRef } from 'hooks';
import { HeaderCompany } from 'layouts/private/components/header-company';
import { HeaderLanguage } from 'layouts/private/components/header-language';
import { HeaderMenuMobile } from 'layouts/private/components/header-menu-mobile';
import React, { useCallback, useState } from 'react';
import { actionAccountLogout } from 'store/auth';
import variables from 'styles/config.scss';
import { HeaderFavorite } from '../header-favorite';
import { HeaderNavigation } from '../header-navigation';
import style from './index.module.scss';

interface Props {
  className?: string;
}

export const Header: React.FC<Props> = ({ className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const mountedRef = useMountedRef();
  const dispatch = useAppDispatch();

  const onLogout = useCallback(async () => {
    setIsLoading(true);

    await dispatch(actionAccountLogout());

    if (!mountedRef.current) return;
    setIsLoading(false);
  }, [mountedRef, dispatch]);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <header className={clsx(style.wrap, className)}>
      <div className={clsx('container', style.inner)}>
        {isMobile && <HeaderMenuMobile />}
        <AppLogo className={style.logo} />
        {!isMobile && <HeaderNavigation />}
        <div className={style.data}>
          <HeaderFavorite />

          {!isMobile && (
            <>
              <Divider orientation={'vertical'} />
              <HeaderCompany />
              <Divider orientation={'vertical'} style={{ alignSelf: 'center', height: '3.6rem' }} />
              <HeaderLanguage />
              <Divider orientation={'vertical'} style={{ alignSelf: 'center', height: '3.6rem' }} />
            </>
          )}

          <div className={style.logout}>
            <ListItem component={'button'} button disabled={isLoading} onClick={onLogout}>
              <LogoutIcon sx={{ color: variables.colorSecondaryLight }} />
            </ListItem>
          </div>
        </div>
      </div>
    </header>
  );
};
