const API = 'StockDiamonds';

export const API_STOCK_DIAMONDS = {
  GET: `${API}/GetAllStockDiamondsDynamic`,
  POST: `${API}/CreateStockDiamond`,
  PATCH: (data: Pick<StockDiamond, 'id'>) => `${API}/PatchStockDiamond/${data.id}`,
  DELETE: (data: Pick<StockDiamond, 'id'>) => `${API}/DeleteStockDiamond/${data.id}`,
};
export class StockDiamondNew {
  agencyID!: string;
  diamondAdvanceBrandID!: string | null | undefined;
  diamondClarityID!: string;
  diamondColorID!: string | null | undefined;
  diamondFancyColorFirstID!: string | null | undefined;
  diamondFancyColorSecondID!: string | null | undefined;
  diamondFancyColorIntensityID!: string | null | undefined;
  diamondFinishCutID!: string | null | undefined;
  diamondFinishPolishID!: string;
  diamondFinishSymmetryID!: string;
  diamondFluorescenceIntensityColorID!: string | null | undefined;
  diamondFluorescenceIntensityWeightID!: string;
  diamondInclusionBlackID!: string | null | undefined;
  diamondInclusionEyeCleanID!: string | null | undefined;
  diamondInclusionMilkyID!: string | null | undefined;
  diamondInclusionOpenID!: string | null | undefined;
  diamondInclusionWhiteID!: string | null | undefined;
  diamondLocationID!: string;
  diamondShadeID!: string | null | undefined;
  diamondShapeID!: string;
  diamondTreatmentTypeID!: string | null | undefined;
  diamondAvailabilityID!: string | null | undefined;
  diamondGirdleConditionID!: string | null | undefined;
  diamondGirdleMinSizeID!: string | null | undefined;
  diamondGirdleMaxSizeID!: string | null | undefined;
  diamondCuletConditionID!: string | null | undefined;
  diamondCuletSizeID!: string | null | undefined;
  diamondLabID!: string;
  entryDate = new Date().toISOString();
  stockNumber = '';
  description: string | null | undefined = '';
  city: string | null | undefined = '';
  listPrice!: number;
  directIcePrice!: number;
  girdlePercentage: number | undefined | null = 0;
  mediaPicture: string | null = '';
  mediaVideo: string | null = '';
  mediaReport: string | null = '';
  size!: number;
  measurementsLength!: number;
  measurementsWidth!: number;
  measurementsDepth!: number;
  depthPercentage: number | null | undefined = 0;
  tablePercentage: number | null | undefined = 0;
  crownAngle: number | null | undefined = 0;
  crownHeight: number | null | undefined = 0;
  pavillionAngle: number | null | undefined = 0;
  pavillionDepth: number | null | undefined = 0;
  laserInscription: string | null | undefined = '';
  starLength: number | null | undefined = 0;
  reportNumber = '';
  reportDate: string | null | undefined = new Date().toISOString();
  labLocation: string | null | undefined = '';
  supplierComment: string | null | undefined = '';
  reportComment: string | null | undefined = '';
  diamondToDiamondOvertoneIDs: string[] = [];
  diamondToDiamondKeySymbolIDs: string[] = [];
}

export type StockDiamondPatch = Pick<StockDiamond, 'id'> & Partial<StockDiamondNew>;

export interface StockDiamond extends StockDiamondNew {
  id: string;
  agencyName: string;
  agencyLogo: string;
  agencyDescription: string;
  diamondAdvanceBrandTitle: string;
  diamondClarityTitle: string;
  diamondColorTitle: string;
  diamondFancyColorFirstTitle: string;
  diamondFancyColorSecondTitle: string;
  diamondFancyColorIntensityTitle: string;
  diamondFinishCutTitle: string;
  diamondFinishPolishTitle: string;
  diamondFinishSymmetryTitle: string;
  diamondFluorescenceIntensityColorTitle: string;
  diamondFluorescenceIntensityWeightTitle: string;
  diamondGradingReportTitle: string;
  diamondInclusionBlackTitle: string;
  diamondInclusionEyeCleanTitle: string;
  diamondInclusionMilkyTitle: string;
  diamondInclusionOpenTitle: string;
  diamondInclusionWhiteTitle: string;
  diamondLocationTitle: string;
  diamondShadeTitle: string;
  diamondShapeTitle: string;
  diamondShapeIcon: string;
  diamondTreatmentTypeTitle: string;
  diamondAvailabilityTitle: string;
  diamondGirdleConditionTitle: string;
  diamondGirdleMinSizeTitle: string;
  diamondGirdleMaxSizeTitle: string;
  diamondCuletConditionTitle: string;
  diamondCuletSizeTitle: string;
  diamondLabTitle: string;
}
