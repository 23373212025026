import { APP_ROUTES } from 'configs';
import { useAppSelector } from 'hooks/redux';
import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { selectAccountUserCompany } from 'store/auth';

interface Props {
  children: React.ReactNode;
}
export const RequireCompany: React.FC<Props> = ({ children }) => {
  const company = useAppSelector(selectAccountUserCompany);
  const location = useLocation();

  const redirect = useMemo(() => {
    return [location.pathname, location.search].filter((v) => v).join('?');
  }, [location.pathname, location.search]);

  if (!company) {
    return <Navigate to={APP_ROUTES.LOGIN_COMPANY({ redirect }).config} replace={true} />;
  }
  return <>{children}</>;
};
