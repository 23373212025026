import { ValueFileUploader } from 'utils/file-uploader';
import { apiApp, uploadImage } from 'utils/service';
import { API_STOCK_JEWELRY_IMAGES, IStockJewelryImage } from './models';

export * from './models';

class Service {
  async post(data: Omit<IStockJewelryImage, 'id' | 'picture'> & { picture: ValueFileUploader }) {
    return apiApp.post(API_STOCK_JEWELRY_IMAGES.POST, {
      ...data,
      picture: await uploadImage(data.picture, null),
    });
  }
  async patch(data: Pick<IStockJewelryImage, 'id' | 'rank'>) {
    return apiApp.patch(API_STOCK_JEWELRY_IMAGES.PATCH(data), {
      rank: data.rank,
    });
  }

  async delete(data: Pick<IStockJewelryImage, 'id'>) {
    return apiApp.delete(API_STOCK_JEWELRY_IMAGES.DELETE(data));
  }
}

export const ServiceStockJewelryImage = new Service();
