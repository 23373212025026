import { useAppSelector } from 'hooks/redux';
import { selectLanguageID } from 'store/labels';

export const useLanguage = () => {
  const languageID = useAppSelector(selectLanguageID);

  if (!languageID) {
    throw new Error('useLanguage: languageID is not exist');
  }

  return { languageID };
};
