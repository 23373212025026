import { createAsyncThunk } from '@reduxjs/toolkit';
import { CurrentAppUser, ServiceAccounts } from 'services/accounts';
import { AppAsyncThunkConfig } from 'store/index';
import { actionLanguagesGet } from 'store/languages';
import { updateAuthTokens } from 'utils/cookies';
import { parseErrorData } from 'utils/service';

export const actionAccountGetUser = createAsyncThunk<CurrentAppUser, void, AppAsyncThunkConfig>(
  `ACCOUNT/actionAccountGetUser`,
  async (_) => {
    try {
      const { data } = await ServiceAccounts.getCurrentUser();
      return data;
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
export const actionAccountGeneratePassword = createAsyncThunk<
  void,
  { email: string },
  AppAsyncThunkConfig
>(`ACCOUNT/actionAccountGeneratePassword`, async (data) => {
  try {
    await ServiceAccounts.generatePassword(data);
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionAccountSedCode = createAsyncThunk<
  void,
  { email: string; code: string },
  AppAsyncThunkConfig
>(`ACCOUNT/actionAccountSedCode`, async (data, { dispatch }) => {
  try {
    const {
      data: {
        expires,
        jwt: { token, refreshToken },
      },
    } = await ServiceAccounts.sendCode(data);
    updateAuthTokens({ token, refreshToken, expires });
    const getUserResult = await dispatch(actionAccountGetUser());
    dispatch(actionLanguagesGet());

    if (actionAccountGetUser.rejected.match(getUserResult)) {
      throw getUserResult.error;
    }
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionAccountLogout = createAsyncThunk<void, void, AppAsyncThunkConfig>(
  `ACCOUNT/actionAccountLogout`,
  async (data) => {
    try {
      await ServiceAccounts.logout();
      updateAuthTokens();
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
