export const setToModel = <Model extends { [x: string]: any }>(
  model: Model,
  ...args: Partial<Model>[]
) => {
  const argsRevers = args.filter(Boolean).reverse();
  const keys = Object.keys(model);
  return keys.reduce<Partial<Model>>((acc, key: keyof Model) => {
    const targetValue: Partial<Model> | undefined = argsRevers.find(
      (item) => item.hasOwnProperty(key) && item[key] !== '' && item[key] !== null,
    );
    acc[key] = targetValue ? targetValue[key] : model[key];
    return acc;
  }, {}) as Model;
};
export const keepToModel = <Model extends { [x: string]: any }>(
  model: Model,
  arg: Partial<Model>,
) => {
  const argsRevers = [arg].filter(Boolean).reverse();
  const keys = Object.keys(model);
  return keys.reduce<Partial<Model>>((acc, key: keyof Model) => {
    const targetValue: Partial<Model> | undefined = argsRevers.find((item) =>
      item.hasOwnProperty(key),
    );
    if (targetValue) {
      acc[key] = targetValue[key];
    }
    return acc;
  }, {} as typeof arg);
};
const dec2hex = (dec: number) => {
  return dec.toString(16).padStart(2, '0');
};
export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
export const getRandomString = (length = 10) => {
  const arr = new Uint8Array((length || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};

export const createMap = <M extends Record<string, any>>(source: M[], key: keyof M) => {
  return source.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {} as Record<string, M | undefined>);
};

export const throttle = <T extends Function>(callback: T, limit: number) => {
  let waiting = false;
  let latestArguments: Array<any> | null = null;
  let latestContext: any | null = null;

  function wrapper(...args: any[]) {
    if (waiting) {
      latestArguments = args;
      // @ts-ignore
      latestContext = this;
      return;
    }

    // @ts-ignore
    callback.apply(this, args);

    waiting = true;

    setTimeout(function () {
      waiting = false;

      if (latestArguments) {
        callback.apply(latestContext, latestArguments);

        latestArguments = null;
        latestContext = null;
      }
    }, limit);
  }

  // @ts-ignore
  return wrapper as T;
};

export const debounce = <T extends Function>(cb: T, delay: number) => {
  let timerID: NodeJS.Timeout | null = null;
  return (...args: ArgumentTypes<T>) => {
    if (timerID) {
      clearTimeout(timerID);
    }
    timerID = setTimeout(() => {
      cb(...args);
      timerID = null;
    }, delay);
  };
};

export const base64ToFileStream = (base64: string) => {
  return base64.split(',')[1];
};
export const getUrlExtension = (str: string) => {
  return str.split('.').pop()?.trim();
};
export const bytesToMB = (value: number | string) => {
  return (Number(value) / 1024 / 1024).toFixed(2);
};
const replaceLabel = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/#.+?#/gi, (v: string) => {
    const key = v.substring(1, v.length - 1);
    return replacer(key);
  });
};
export const calcRequestLabel = (value: string, replacer: (v: string) => string) => {
  const _value = String(value);
  if (RegExp(/^@.+@$/gi).test(_value)) {
    return replaceLabel(_value.substring(1, _value.length - 1), replacer);
  } else {
    return replacer(_value);
  }
};
const replaceTranslate = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/{{[a-zA-Z]+?}}/g, (v: string) => {
    const key = v.substring(2, v.length - 2);
    return replacer(key);
  });
};
export const calcTranslate = (value: string, payload: { [x: string]: any } = {}) => {
  return replaceTranslate(value, (key) => {
    return payload[key] === undefined ? '-- --' : payload[key];
  });
};

export const fieldToLabelKey = <T extends Record<string, any> = any>(field: keyof T) => {
  return String(field)
    .replace(/ID/g, '')
    .replace(/[A-Z]/g, (substring) => {
      return `-${substring}`;
    })
    .toLowerCase()
    .replace(/^-/gi, '');
};
export const fieldToLabelKeyNamespace = <T extends Record<string, any> = any>() => {
  return (field: keyof T) => fieldToLabelKey<T>(field);
};

// TODO: START needs to delete
const defaultDecorator = () => {
  return undefined;
};
export const decorateAfter = <T extends (...args: any[]) => any>(
  cb: T,
  decorator: (...args: any[]) => any = defaultDecorator,
) => {
  return (...args: any[]) => {
    const result = cb(...args);
    decorator && decorator(...args);
    return result;
  };
};
// TODO: END needs to delete

type AnyFunction = (...args: any[]) => any;
export const composeFunctions = <T extends (...args: any[]) => any>(
  cb: T,
  ...functions: (AnyFunction | undefined)[]
) => {
  return (...args: any[]) => {
    const result = cb(...args);
    (functions.filter(Boolean) as AnyFunction[]).forEach((fn) => {
      fn(...args);
    });
    return result;
  };
};

export const makeRankAsID = <T extends { rank: number }>(item: T) => {
  return { ...item, id: item.rank };
};
