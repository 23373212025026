import clsx from 'clsx';
import { ReactComponent as LogoDesktop } from 'images/logo-desktop.svg';
import React from 'react';

interface Props {
  className?: string;
}

export const AppLogo: React.FC<Props> = ({ className }) => {
  return (
    <div className={clsx(className)} style={{ lineHeight: 0 }}>
      <LogoDesktop />
    </div>
  );
};
