import { useEffect, useMemo } from 'react';

const insertFirst = (parent: HTMLElement, element: DocumentFragment) => {
  const firstElement = parent.firstChild;
  return firstElement ? parent.insertBefore(element, firstElement) : parent.appendChild(element);
};
const insertLast = (parent: HTMLElement, element: DocumentFragment) => {
  return parent.appendChild(element);
};

interface Options {
  parent: HTMLElement;
  insert?: 'first' | 'last';
}

export const useRawScript = (htmlString: string | undefined | null, options: Options) => {
  const { parent, insert = 'last' } = options;

  const insertBehaviour = useMemo(() => {
    switch (insert) {
      case 'first':
        return insertFirst;
      default:
        return insertLast;
    }
  }, [insert]);

  useEffect(() => {
    let scriptEl: null | DocumentFragment = null;
    if (htmlString) {
      scriptEl = document.createRange().createContextualFragment(htmlString);
      insertBehaviour(parent, scriptEl);
    }
    return () => {
      try {
        scriptEl && parent.removeChild(scriptEl);
      } catch (e) {}
    };
  }, [htmlString, parent, insertBehaviour]);
};
