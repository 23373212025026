import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useCallback, useMemo } from 'react';
import {
  actionAccountSetCompanyID,
  selectAccountUserCompanies,
  selectAccountUserCompanyID,
} from 'store/auth';

export const useUserCompanies = () => {
  const dispatch = useAppDispatch();

  const companyID = useAppSelector(selectAccountUserCompanyID);
  const companies = useAppSelector(selectAccountUserCompanies);

  const companiesSource = useMemo(() => {
    return companies.map((item) => ({ id: item.agencyID, title: item.agencyName }));
  }, [companies]);
  const onChange = useCallback(
    (v: string) => {
      dispatch(actionAccountSetCompanyID(v));
    },
    [dispatch],
  );

  const source = useMemo(() => {
    return {
      isLoading: false,
      data: companiesSource,
    };
  }, [companiesSource]);

  return { companyID, onChange, source };
};
