import { useTranslate } from 'hooks';
import Logo from 'images/logo-desktop-login.svg';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import style from './index.module.scss';

export const LoginLayout = memo(() => {
  const { t } = useTranslate();
  return (
    <div className={style.wrap}>
      <div className={style.header}>
        <div className={style.logo}>
          <img src={Logo} alt="" />
        </div>
      </div>
      <div className={style.content}>
        <div className={style.form}>
          <Outlet />
        </div>
      </div>
      <div className={style.footer}>
        <div className={style.copy}>{t('copyright')}</div>
      </div>
    </div>
  );
});
