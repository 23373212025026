import { Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import style from './index.module.scss';

export class ErrorBoundary extends React.Component<PropsWithChildren<{}>, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={style.wrap}>
          <Typography variant={'h1'}>Something went wrong ;(</Typography>
        </div>
      );
    }
    return this.props.children;
  }
}
