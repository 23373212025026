import { APP_ROUTES } from 'configs';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ServiceAccounts } from 'services/accounts';
import { getAuthTokens, updateAuthTokens } from 'utils/cookies';
import { AxiosInterceptorPatch } from './patch';
import { AxiosInterceptorPost } from './post';
import { AxiosInterceptorsRefreshToken } from './refresh-token';
import { AxiosInterceptorsToken } from './token';

export const AxiosInterceptorsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [error, setError] = useState<Error>();
  const onRefresh = useCallback(async () => {
    const { token: Token, refreshToken: RefreshToken, expires } = getAuthTokens();

    if (!Token || !RefreshToken || !expires) {
      throw new Error('token-not-found');
    }

    const {
      data: { token, refreshToken },
    } = await ServiceAccounts.refreshToken({
      token: Token,
      refreshToken: RefreshToken,
    });

    return { token, refreshToken, expires };
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (error) {
      updateAuthTokens();
      navigate(APP_ROUTES.LOGIN({ redirect: window.location.origin }).config, { replace: true });
      setError(undefined);
    }
  }, [error, navigate]);

  return (
    <AxiosInterceptorsToken>
      <AxiosInterceptorsRefreshToken
        onRefresh={onRefresh}
        onError={setError}
        onSuccess={updateAuthTokens}
      >
        <AxiosInterceptorPatch>
          <AxiosInterceptorPost>{children}</AxiosInterceptorPost>
        </AxiosInterceptorPatch>
      </AxiosInterceptorsRefreshToken>
    </AxiosInterceptorsToken>
  );
};
