import { apiApp } from 'utils/service';
import { API_STOCK_DIAMOND_TO_DIAMOND_KEY_SYMBOLS } from './models';

export * from './models';

class Service {
  async createBulk(diamondID: string, diamondKeySymbolIDs: string[]) {
    return apiApp.post(API_STOCK_DIAMOND_TO_DIAMOND_KEY_SYMBOLS.POST_BULK, {
      diamondID,
      diamondKeySymbolIDs,
    });
  }
}

export const ServiceStockDiamondsToDiamondKeySymbols = new Service();
