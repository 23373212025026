import { createSelector } from '@reduxjs/toolkit';
import { initStateMyFavorites } from 'store/my-favorite/slice';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';

const selectState = (state: AppState) => state.myFavorite;

export const selectMyFavoriteStatuses = createSelector(
  selectState,
  ({ isLoading, isInit, error }) => {
    return { isLoading, isInit, error };
  },
);
export const selectMyFavoriteData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectMyFavoriteOrder = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});
export const selectMyFavoriteView = createSelector(selectState, ({ view }) => {
  return view;
});
export const selectMyFavoriteFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectMyFavoriteFiltersHas = createSelector(selectMyFavoriteFilters, (filters) => {
  return JSON.stringify(initStateMyFavorites().filters) !== JSON.stringify(filters);
});

export const selectMyFavoriteShowedColumns = createSelector(selectState, ({ showedColumns }) => {
  return showedColumns;
});
export const selectMyFavoritePagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
