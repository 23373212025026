import { StockDiamond } from 'services/stock-diamonds';

const API = 'TradeDiamonds';

export const API_TRADE_DIAMONDS = {
  GET: `${API}/GetAllTradeDiamondsDynamic`,
  ADD_TO_FAVORITE: `${API}/AddDiamondToFavorite`,
  DELETE_FROM_FAVORITE: (data: Pick<TradeDiamond, 'id' | 'agencyID'>) =>
    `${API}/RemoveDiamondFromFavorite/${data.agencyID}/${data.id}`,
};

export interface TradeDiamond extends StockDiamond {
  messages: Message[];
  inFavorites: boolean;
  diamondColorRank: number;
  diamondFinishCutRank: number;
  diamondClarityRank: number;
}
export interface Message {
  diamondID: string;
  agencyID: string;
  entryDate: string;
  message: string;
  id: string;
  agencyName: string;
  isOwnerMessage: boolean;
  isAllowToRemoveMessage: boolean;
}
