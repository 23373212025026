import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DiamondMessage,
  DiamondMessagePost,
  ServiceDiamondMessages,
} from 'services/diamond-messages';
import { selectAccountUserCompanyID } from 'store/auth';
import { AppAsyncThunkConfig } from 'store/index';
import { parseErrorData } from 'utils/service';

export const actionDiamondMessagesCreate = createAsyncThunk<
  { diamondID: string; data: DiamondMessage },
  { diamondID: string; data: Omit<DiamondMessagePost, 'agencyID'> },
  AppAsyncThunkConfig
>(`DIAMOND_MESSAGES/create`, async ({ diamondID, data }, { getState }) => {
  const agencyID = selectAccountUserCompanyID(getState());

  try {
    if (!agencyID) {
      throw new Error('unexpected-behaviour');
    }
    const { data: entity } = await ServiceDiamondMessages.post({ ...data, agencyID });

    return { diamondID, data: entity };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionDiamondMessagesDelete = createAsyncThunk<
  { diamondID: string; messageID: string },
  { diamondID: string; messageID: string },
  AppAsyncThunkConfig
>(`DIAMOND_MESSAGES/delete`, async ({ diamondID, messageID }, { getState }) => {
  const agencyID = selectAccountUserCompanyID(getState());

  try {
    if (!agencyID) {
      throw new Error('unexpected-behaviour');
    }
    await ServiceDiamondMessages.delete({ agencyID, messageID });

    return { diamondID, messageID };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
