import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceTradeDiamonds, TradeDiamond } from 'services/trade-diamonds';
import { selectAccountUserCompanyID } from 'store/auth';
import { AppAsyncThunkConfig } from 'store/index';
import { calcDiamondOrderBy } from 'store/shared';
import {
  contains,
  decoratorArray,
  decoratorIsNotNullable,
  decoratorIsNumber,
  decoratorValueArray,
  dynamicNamespace,
  equals,
  lessOrEquals,
  mergeFilters,
  moreOrEquals,
} from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import {
  selectMarketplaceFilters,
  selectMarketplaceOrder,
  selectMarketplacePagination,
} from './selectors';

const dynamic = dynamicNamespace<TradeDiamond>();

export const actionMarketplaceGet = createAsyncThunk<
  { data: TradeDiamond[]; count: number },
  void,
  AppAsyncThunkConfig
>(`MARKETPLACE/get`, async (_, { getState }) => {
  const agencyID = selectAccountUserCompanyID(getState());

  if (!agencyID) {
    throw new Error('unexpected-behaviour');
  }

  const { skip, take } = selectMarketplacePagination(getState());
  const order = selectMarketplaceOrder(getState());
  const {
    search,
    diamondShapeIDs,
    diamondColorRanks,
    diamondClarityRanks,
    diamondLocationID,
    diamondLabID,

    diamondFancyColorID,
    diamondFinishCutRanks,
    diamondFinishPolishID,
    diamondFinishSymmetryID,
    diamondFluorescenceIntensityColorID,
    diamondFluorescenceIntensityWeightID,

    diamondFancyColorIntensityID,
    diamondFancyColorSecondID,
    diamondToDiamondOvertoneIDs,

    sizeRange,
  } = selectMarketplaceFilters(getState());
  try {
    const {
      data: { value: data, count },
    } = await ServiceTradeDiamonds.getAllDynamic({
      agencyID,
      count: true,
      skip,
      take,
      filter: mergeFilters(
        dynamic.makeFilter(
          'diamondColorRank',
          Math.min(...diamondColorRanks),
          decoratorIsNumber(moreOrEquals),
        ),
        dynamic.makeFilter(
          'diamondColorRank',
          Math.max(...diamondColorRanks),
          decoratorIsNumber(lessOrEquals),
        ),

        dynamic.makeFilter(
          'diamondClarityRank',
          Math.min(...diamondClarityRanks),
          decoratorIsNumber(moreOrEquals),
        ),
        dynamic.makeFilter(
          'diamondClarityRank',
          Math.max(...diamondClarityRanks),
          decoratorIsNumber(lessOrEquals),
        ),

        dynamic.makeFilter(
          'diamondFinishCutRank',
          Math.min(...diamondFinishCutRanks),
          decoratorIsNumber(moreOrEquals),
        ),
        dynamic.makeFilter(
          'diamondFinishCutRank',
          Math.max(...diamondFinishCutRanks),
          decoratorIsNumber(lessOrEquals),
        ),

        dynamic.makeFilter(
          [
            'agencyName',
            'stockNumber',
            'description',
            'labLocation',
            'reportComment',
            'reportNumber',
            'city',
          ],
          search,
          decoratorIsNotNullable(contains),
        ),

        dynamic.makeFilter('size', sizeRange[0], decoratorIsNumber(moreOrEquals)),
        dynamic.makeFilter('size', sizeRange[1], decoratorIsNumber(lessOrEquals)),

        dynamic.makeFilter('diamondShapeID', diamondShapeIDs, decoratorValueArray(equals)),
        dynamic.makeFilter('diamondLocationID', diamondLocationID, decoratorIsNotNullable(equals)),
        dynamic.makeFilter('diamondLabID', diamondLabID, decoratorIsNotNullable(equals)),
        dynamic.makeFilter(
          ['diamondFancyColorFirstID', 'diamondFancyColorSecondID'],
          diamondFancyColorID,
          decoratorIsNotNullable(equals),
        ),

        dynamic.makeFilter(
          'diamondFinishPolishID',
          diamondFinishPolishID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFinishSymmetryID',
          diamondFinishSymmetryID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFluorescenceIntensityColorID',
          diamondFluorescenceIntensityColorID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFluorescenceIntensityWeightID',
          diamondFluorescenceIntensityWeightID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFancyColorIntensityID',
          diamondFancyColorIntensityID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFancyColorSecondID',
          diamondFancyColorSecondID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondToDiamondOvertoneIDs',
          diamondToDiamondOvertoneIDs,
          decoratorArray(equals),
        ),
        // createFilterArrays<TradeDiamond>(
        //   'diamondToDiamondOvertoneIDs',
        //   diamondToDiamondOvertoneIDs,
        // ),
      ).join('&&'),
      orderBy: calcDiamondOrderBy(order),
    });
    return { data, count };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
