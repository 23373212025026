import { createSelector } from '@reduxjs/toolkit';
import { initStateMarketplace } from 'store/marketplace/slice';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';

const selectState = (state: AppState) => state.marketplace;

export const selectMarketplaceStatuses = createSelector(
  selectState,
  ({ isLoading, isInit, error }) => {
    return { isLoading, isInit, error };
  },
);
export const selectMarketplaceData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectMarketplaceOrder = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});
export const selectMarketplaceView = createSelector(selectState, ({ view }) => {
  return view;
});
export const selectMarketplaceFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectMarketplaceFiltersHas = createSelector(selectMarketplaceFilters, (filters) => {
  return JSON.stringify(initStateMarketplace().filters) !== JSON.stringify(filters);
});
export const selectMarketplaceShowedColumns = createSelector(selectState, ({ showedColumns }) => {
  return showedColumns;
});
export const selectMarketplacePagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
