import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiStockDiamonds,
  ServiceStockDiamonds,
  StockDiamond,
  StockDiamondNew,
  StockDiamondPatch,
} from 'services/stock-diamonds';
import { selectAccountUserCompanyID } from 'store/auth';
import { AppAsyncThunkConfig } from 'store/index';
import { calcDiamondOrderBy } from 'store/shared';
import {
  selectStockFilters,
  selectStockOrder,
  selectStockPagination,
} from 'store/stocks/selectors';
import {
  contains,
  decoratorArray,
  decoratorIsNotNullable,
  decoratorIsNumber,
  decoratorValueArray,
  dynamicNamespace,
  equals,
  lessOrEquals,
  mergeFilters,
  moreOrEquals,
} from 'utils/dynamic';
import { apiRtk, parseErrorData, RTK_TAGS } from 'utils/service';

const dynamic = dynamicNamespace<StockDiamond>();

export const actionStockGet = createAsyncThunk<
  { data: StockDiamond[]; count: number },
  void,
  AppAsyncThunkConfig
>(`STOCKS/actionStocksGet`, async (_, { getState }) => {
  const agencyID = selectAccountUserCompanyID(getState());

  if (!agencyID) {
    throw new Error('Unexpected behaviour');
  }

  const { skip, take } = selectStockPagination(getState());
  const order = selectStockOrder(getState());
  const {
    search,
    diamondShapeIDs,
    diamondColorIDs,
    diamondClarityIDs,
    diamondLocationID,
    diamondLabID,
    diamondFancyColorID,
    diamondFinishCutIDs,
    diamondFinishPolishID,
    diamondFinishSymmetryID,
    diamondFluorescenceIntensityColorID,
    diamondFluorescenceIntensityWeightID,

    diamondFancyColorIntensityID,
    diamondFancyColorSecondID,
    diamondToDiamondOvertoneIDs,

    sizeFrom,
    sizeTo,
  } = selectStockFilters(getState());
  try {
    const {
      data: { value: data, count },
    } = await ServiceStockDiamonds.getAllDynamic({
      agencyID,
      count: true,
      skip,
      take,
      //TODO REMOVED because the query string is too long
      // select: dynamic.select(
      //   'id',
      //   'stockNumber',
      //   'entryDate',
      //   'diamondLocationTitle',
      //   'diamondLabTitle',
      //   'diamondShapeTitle',
      //   'size',
      //   'diamondColorTitle',
      //   'diamondClarityTitle',
      //   'diamondFinishCutTitle',
      //   'diamondFinishPolishTitle',
      //   'listPrice',
      //   'directIcePrice',
      //   'diamondAdvanceBrandTitle',
      //   'diamondFancyColorSecondTitle',
      //   'diamondFancyColorIntensityTitle',
      //   'diamondFinishSymmetryTitle',
      //   'diamondFluorescenceIntensityColorTitle',
      //   'diamondFluorescenceIntensityWeightTitle',
      //   'diamondInclusionBlackTitle',
      //   'diamondInclusionEyeCleanTitle',
      //   'diamondInclusionMilkyTitle',
      //   'diamondInclusionOpenTitle',
      //   'diamondInclusionWhiteTitle',
      //   'diamondShadeTitle',
      //   'diamondTreatmentTypeTitle',
      //   'diamondAvailabilityTitle',
      //   'diamondGirdleConditionTitle',
      //   'diamondCuletConditionTitle',
      //   'diamondCuletSizeTitle',
      //   'description',
      //   'labLocation',
      //   'city',
      //   'measurementsLength',
      //   'measurementsWidth',
      //   'measurementsDepth',
      //   'depthPercentage',
      //   'tablePercentage',
      //   'crownAngle',
      //   'crownHeight',
      //   'pavillionAngle',
      //   'pavillionDepth',
      //   'laserInscription',
      //   'starLength',
      //   'reportNumber',
      //   'reportDate',
      //   'reportComment',
      //   'diamondShapeIcon',
      //   'mediaPicture',
      //   'mediaReport',
      //   'mediaVideo',
      //   'diamondFancyColorFirstTitle',
      // ),
      filter: mergeFilters(
        dynamic.makeFilter(
          ['stockNumber', 'description', 'labLocation', 'reportComment', 'reportNumber', 'city'],
          search,
          contains,
        ),
        dynamic.makeFilter('diamondShapeID', diamondShapeIDs, decoratorValueArray(equals)),
        dynamic.makeFilter('diamondColorID', diamondColorIDs, decoratorValueArray(equals)),
        dynamic.makeFilter('diamondClarityID', diamondClarityIDs, decoratorValueArray(equals)),
        dynamic.makeFilter('diamondLocationID', diamondLocationID, decoratorIsNotNullable(equals)),
        dynamic.makeFilter('diamondLabID', diamondLabID, decoratorIsNotNullable(equals)),
        dynamic.makeFilter('size', sizeFrom, decoratorIsNumber(moreOrEquals)),
        dynamic.makeFilter('size', sizeTo, decoratorIsNumber(lessOrEquals)),

        dynamic.makeFilter(
          ['diamondFancyColorFirstID', 'diamondFancyColorSecondID'],
          diamondFancyColorID,
          decoratorIsNotNullable(equals),
        ),

        dynamic.makeFilter('diamondFinishCutID', diamondFinishCutIDs, decoratorValueArray(equals)),
        dynamic.makeFilter(
          'diamondFinishPolishID',
          diamondFinishPolishID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFinishSymmetryID',
          diamondFinishSymmetryID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFluorescenceIntensityColorID',
          diamondFluorescenceIntensityColorID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFluorescenceIntensityWeightID',
          diamondFluorescenceIntensityWeightID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFancyColorIntensityID',
          diamondFancyColorIntensityID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFancyColorSecondID',
          diamondFancyColorSecondID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondToDiamondOvertoneIDs',
          diamondToDiamondOvertoneIDs,
          decoratorArray(equals),
        ),
      ).join('&&'),
      orderBy: calcDiamondOrderBy(order),
    });
    return { data, count };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionStockDelete = createAsyncThunk<void, { id: string }, AppAsyncThunkConfig>(
  `STOCKS/actionStockDelete`,
  async ({ id }, { dispatch }) => {
    try {
      await ServiceStockDiamonds.delete({ id });
      dispatch(actionStockGet());
      dispatch(
        apiRtk.util.invalidateTags([
          { type: RTK_TAGS.STOCK_DIAMOND },
          { type: RTK_TAGS.STOCK_DIAMOND, id },
        ]),
      );
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
export const actionStockCreate = createAsyncThunk<
  StockDiamond,
  StockDiamondNew,
  AppAsyncThunkConfig
>(`STOCKS/actionStockCreate`, async (data, { dispatch, getState }) => {
  const agencyID = selectAccountUserCompanyID(getState());
  try {
    if (!agencyID) {
      throw new Error('unexpected-behaviour');
    }
    const result = await ServiceStockDiamonds.create({ ...data, agencyID });
    dispatch(actionStockGet());
    return result.data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionStockPatch = createAsyncThunk<
  void,
  { newData: StockDiamondPatch; oldData: StockDiamondPatch },
  AppAsyncThunkConfig
>(`STOCKS/actionStockPatch`, async ({ newData, oldData }, { dispatch }) => {
  try {
    const result = await ServiceStockDiamonds.patch(newData, oldData);
    dispatch(actionStockGet());
    dispatch(
      apiStockDiamonds.util.invalidateTags([{ type: RTK_TAGS.STOCK_DIAMOND, id: oldData.id }]),
    );
    return result.data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
