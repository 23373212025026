import { StockDiamondNew } from 'services/stock-diamonds';

export interface TranslateMap {
  'invitation-to-company-content': { companyName: string; userName: string };
  'data-grid-pagination': { total: number; start: number; end: number };
  'data-grid-showing-results': { total: number };
  'data-grid-delete': { name: string };
  'my-stock-new': void;
  'my-stock-edit': StockDiamondNew;
  cancel: void;
  'direct-ice-price': void;
  close: void;
  submit: void;
  browse: void;
  'upload-image': void;
  'upload-video': void;
  'upload-video-dialog-title': void;
  'upload-video-dialog-description': void;
  'upload-video-method': void;
  'upload-video-methods': void;
  'upload-certificate': void;
  'watch-video': void;
  features: void;
  add: void;
  loading: void;
  logout: void;
  create: void;
  image: void;
  certificate: void;
  video: void;
  save: void;
  delete: void;
  'rule-required': void;
  edit: void;
  'select-images': void;
  'dialog-select-images': void;
  'general-information': void;
  stock: void;
  price: void;
  'diamond-availability': void;
  'diamond-advance-brand': void;
  'diamond-location': void;
  'entry-date': void;
  description: void;
  'supplier-comment': void;
  'diamond-messages': void;
  'diamond-similar': void;
  'type-messages': void;
  'no-messages': void;
  'no-similar': void;
  'report-information': void;
  'main-characteristics': void;
  fluorescence: void;
  finish: void;
  measurements: void;
  depth: void;
  table: void;
  'grid-edit-cell-helper': void;
  'invalid-date': void;
  category: void;
  metal: void;
  'product-info': void;
  'main-diamond-information': void;
  'main-diamond-information-helper': void;
  'main-diamond-characteristics': void;
  'other-diamond-information': void;
  'other-diamond-characteristics': void;
  'diamond-certificate': void;
  'my-jewelries-stock': void;
  'my-jewelries-stock-new': void;
  'jewelry-ring-size': void;
  'file-guide-image-multiple': { maxCount: number; maxSize: number; accept: string };
  'file-guide-video': { maxSize: number; accept: string };
  'file-guide-image': { maxSize: number; accept: string };
  'file-guide-file': { maxSize: number; accept: string };
  clear: void;
  'view-video': void;
  'view-pictures': void;
  'jewelry-metal-type': void;
  'jewelry-metal-color': void;
  'jewelry-metal-karat': void;
  'diamond-shape-title': void;
  'diamond-shape': void;
  'diamond-clarity': void;
  'diamond-finish-cut': void;
  'diamond-lab': void;
  'diamond-color': void;
  length: void;
  color: void;
  size: void;
  media: void;
  'media-files': void;
  'diamond-certificate-for-website': void;
}

export type TranslateProps<K extends keyof TranslateMap> = TranslateMap[K] extends void
  ? [K]
  : [K, TranslateMap[K]];

export enum TRANSLATE {
  CROWN = 'crown',
  PAVILLION = 'pavillion',
  GIRDLE = 'girdle',
  CULET = 'culet',
  TREATMENT = 'treatment',
  LASER_INSCRIPTION = 'laser-inscription',
  STAR_LENGTH = 'star-length',
  LAB = 'lab',
  REPORT_NUMBER = 'report-number',
  REPORT_DATE = 'report-date',
  LAB_LOCATION = 'lab-location',
  REPORT_COMMENT = 'report-comment',
  SUPPLIER_COMMENT = 'supplier-comment',
  KEY_SYMBOLS = 'diamond-to-diamond-key-symbols',
  FANCY_COLOR = 'fancy-color',

  ADDITIONAL_INFORMATION = 'additional-information',
  SELLER_SPEC = 'seller-spec',
  SHADE = 'shade',
  MILKY = 'diamond-inclusion-milky',
  EYE_CLEAN = 'diamond-inclusion-eye-clean',
  INCLUSION_OPEN = 'diamond-inclusion-open',
  INCLUSION_BLACK = 'diamond-inclusion-black',
  INCLUSION_WHITE = 'diamond-inclusion-white',

  UPLOAD = 'upload',
  FILE_ERROR_TYPE = 'file-error-type',
  FILE_ERROR_SIZE = 'file-error-size',
  FILE_ERROR_LOAD = 'file-error-load',
  MEDIA_FILES = 'media-files',

  SIZE = 'size',
  FROM = 'from',
  TO = 'to',
  OVERTONE = 'overtone',
  SEARCH = 'search',

  MIN = 'min',
  MAX = 'max',
}

type Translate<K extends string, P extends { [x: string]: any } | void = void> = P extends void
  ? (key: K) => string
  : (key: K, payload: P) => string;

export type LabelTranslateProps = Parameters<
  | Translate<TRANSLATE.ADDITIONAL_INFORMATION>
  | Translate<TRANSLATE.SELLER_SPEC>
  | Translate<TRANSLATE.EYE_CLEAN>
  | Translate<TRANSLATE.MILKY>
  | Translate<TRANSLATE.UPLOAD>
  | Translate<TRANSLATE.OVERTONE>
  | Translate<TRANSLATE.FROM>
  | Translate<TRANSLATE.TO>
  | Translate<TRANSLATE.INCLUSION_BLACK>
  | Translate<TRANSLATE.SHADE>
  | Translate<TRANSLATE.INCLUSION_OPEN>
  | Translate<TRANSLATE.INCLUSION_WHITE>
  | Translate<TRANSLATE.KEY_SYMBOLS>
  | Translate<TRANSLATE.FANCY_COLOR>
  | Translate<TRANSLATE.CROWN>
  | Translate<TRANSLATE.SIZE>
  | Translate<TRANSLATE.PAVILLION>
  | Translate<TRANSLATE.LASER_INSCRIPTION>
  | Translate<TRANSLATE.STAR_LENGTH>
  | Translate<TRANSLATE.LAB>
  | Translate<TRANSLATE.REPORT_NUMBER>
  | Translate<TRANSLATE.REPORT_DATE>
  | Translate<TRANSLATE.REPORT_COMMENT>
  | Translate<TRANSLATE.LAB_LOCATION>
  | Translate<TRANSLATE.CULET>
  | Translate<TRANSLATE.GIRDLE>
  | Translate<TRANSLATE.TREATMENT>
  | Translate<TRANSLATE.FILE_ERROR_TYPE, { accept: string }>
  | Translate<TRANSLATE.FILE_ERROR_SIZE, { maxSize: number }>
  | Translate<TRANSLATE.FILE_ERROR_LOAD>
  | Translate<TRANSLATE.MEDIA_FILES>
  | Translate<TRANSLATE.MIN>
  | Translate<TRANSLATE.MAX>
  | Translate<TRANSLATE.SEARCH>
>;
