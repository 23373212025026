import { apiApp, apiRtk, DynamicResult } from 'utils/service';
import { API_PARAMETERS, Language } from './models';
export * from './models';

class Service {
  async getLanguages() {
    return apiApp.get<DynamicResult<Language>>(API_PARAMETERS.GET_LANGUAGES, {
      params: { orderBy: 'rank asc' },
    });
  }
}
export const ServiceParams = new Service();
export const apiParameters = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {};
  },
});
