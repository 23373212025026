import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceTradeDiamonds, TradeDiamond } from 'services/trade-diamonds';
import { selectAccountUserCompanyID } from 'store/auth';
import { AppAsyncThunkConfig } from 'store/index';
import { calcDiamondOrderBy } from 'store/shared';
import {
  contains,
  decoratorArray,
  decoratorIsNotNullable,
  decoratorIsNumber,
  decoratorValueArray,
  dynamicNamespace,
  equals,
  lessOrEquals,
  mergeFilters,
  moreOrEquals,
} from 'utils/dynamic';
import { apiRtk, parseErrorData, RTK_TAGS } from 'utils/service';
import {
  selectMyFavoriteFilters,
  selectMyFavoriteOrder,
  selectMyFavoritePagination,
} from './selectors';

const dynamic = dynamicNamespace<TradeDiamond>();
export const actionMyFavoriteGet = createAsyncThunk<
  { data: TradeDiamond[]; count: number },
  void,
  AppAsyncThunkConfig
>(`MY_FAVORITE/get`, async (_, { getState }) => {
  const agencyID = selectAccountUserCompanyID(getState());

  if (!agencyID) {
    throw new Error('unexpected-behaviour');
  }

  const { skip, take } = selectMyFavoritePagination(getState());
  const order = selectMyFavoriteOrder(getState());
  const {
    search,
    diamondShapeIDs,
    diamondColorIDs,
    diamondClarityIDs,
    diamondLocationID,
    diamondLabID,
    diamondFancyColorID,
    diamondFinishCutIDs,
    diamondFinishPolishID,
    diamondFinishSymmetryID,
    diamondFluorescenceIntensityColorID,
    diamondFluorescenceIntensityWeightID,

    diamondFancyColorIntensityID,
    diamondFancyColorSecondID,
    diamondToDiamondOvertoneIDs,

    sizeFrom,
    sizeTo,
  } = selectMyFavoriteFilters(getState());
  try {
    const {
      data: { value: data, count },
    } = await ServiceTradeDiamonds.getAllDynamic({
      agencyID,
      count: true,
      skip,
      take,
      filter: mergeFilters(
        dynamic.makeFilter('inFavorites', true, equals),
        dynamic.makeFilter(
          [
            'agencyName',
            'stockNumber',
            'description',
            'labLocation',
            'reportComment',
            'reportNumber',
            'city',
          ],
          search,
          contains,
        ),
        dynamic.makeFilter('diamondShapeID', diamondShapeIDs, decoratorValueArray(equals)),
        dynamic.makeFilter('diamondColorID', diamondColorIDs, decoratorValueArray(equals)),
        dynamic.makeFilter('diamondClarityID', diamondClarityIDs, decoratorValueArray(equals)),
        dynamic.makeFilter('diamondLocationID', diamondLocationID, decoratorIsNotNullable(equals)),
        dynamic.makeFilter('diamondLabID', diamondLabID, decoratorIsNotNullable(equals)),
        dynamic.makeFilter('size', sizeFrom, decoratorIsNumber(moreOrEquals)),
        dynamic.makeFilter('size', sizeTo, decoratorIsNumber(lessOrEquals)),

        dynamic.makeFilter(
          ['diamondFancyColorFirstID', 'diamondFancyColorSecondID'],
          diamondFancyColorID,
          decoratorIsNotNullable(equals),
        ),

        dynamic.makeFilter('diamondFinishCutID', diamondFinishCutIDs, decoratorValueArray(equals)),
        dynamic.makeFilter(
          'diamondFinishPolishID',
          diamondFinishPolishID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFinishSymmetryID',
          diamondFinishSymmetryID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFluorescenceIntensityColorID',
          diamondFluorescenceIntensityColorID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFluorescenceIntensityWeightID',
          diamondFluorescenceIntensityWeightID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFancyColorIntensityID',
          diamondFancyColorIntensityID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondFancyColorSecondID',
          diamondFancyColorSecondID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter(
          'diamondToDiamondOvertoneIDs',
          diamondToDiamondOvertoneIDs,
          decoratorArray(equals),
        ),
      ).join('&&'),
      orderBy: calcDiamondOrderBy(order),
    });
    return { data, count };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionMyFavoriteAddFavorite = createAsyncThunk<void, string, AppAsyncThunkConfig>(
  `MY_FAVORITE/addToFavorite`,
  async (diamondID, { getState, dispatch }) => {
    const agencyID = selectAccountUserCompanyID(getState());

    if (!agencyID) {
      throw new Error('Unexpected behaviour');
    }
    try {
      await ServiceTradeDiamonds.addToFavorite({ agencyID, diamondID });
      dispatch(apiRtk.util.invalidateTags([{ type: RTK_TAGS.STOCK_DIAMOND, id: diamondID }]));
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
export const actionMyFavoriteRemoveFavorite = createAsyncThunk<void, string, AppAsyncThunkConfig>(
  `MY_FAVORITE/removeToFavorite`,
  async (diamondID, { getState, dispatch }) => {
    const agencyID = selectAccountUserCompanyID(getState());

    if (!agencyID) {
      throw new Error('Unexpected behaviour');
    }
    try {
      await ServiceTradeDiamonds.removeFromFavorite({ agencyID, diamondID });
      dispatch(apiRtk.util.invalidateTags([{ type: RTK_TAGS.STOCK_DIAMOND, id: diamondID }]));
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
