import { MenuSelect } from 'components/menu-select';
import { useDecorator } from 'hooks/use-action-decorators';
import { useUserCompanies } from 'hooks/use-user-companies';
import React from 'react';

interface Props {
  onChanged?: () => void;
}

export const HeaderCompany: React.FC<Props> = ({ onChanged }) => {
  const {
    companyID,
    source: { data },
    onChange,
  } = useUserCompanies();
  return (
    <MenuSelect value={companyID} options={data} onChange={useDecorator(onChange, onChanged)} />
  );
};
