const API = 'DiamondMessages';

export const API_DIAMOND_MESSAGES = {
  GET: `${API}/GetAllDiamondMessagesDynamic`,
  POST: `${API}/AddMessage`,
  DELETE: (data: Pick<DiamondMessage, 'agencyID' | 'id'>) =>
    `${API}/RemoveMessage/${data.agencyID}/${data.id}`,
};

export interface DiamondMessage {
  diamondID: string;
  agencyID: string;
  entryDate: string;
  message: string;
  id: string;
  agencyName: string;
  isOwnerMessage: boolean;
  isAllowToRemoveMessage: boolean;
}

export interface DiamondMessagePost
  extends Pick<DiamondMessage, 'diamondID' | 'agencyID' | 'message'> {}
