import { createSelector } from '@reduxjs/toolkit';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';

const selectState = (state: AppState) => state.diamondImages;

export const selectDiamondImagesStatuses = createSelector(
  selectState,
  ({ isLoading, isInit, error }) => {
    return { isLoading, isInit, error };
  },
);
export const selectDiamondImagesData = createSelector(selectState, ({ data }) => {
  return data;
});

export const selectDiamondImagesFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectDiamondImagesPagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
