import { put, select, takeEvery } from 'modules/typed-saga';
import { selectDashboardFilters } from 'store/dashboard/selectors';
import { actionDashboardApply } from 'store/dashboard/slice';
import {
  actionMarketplaceGet,
  actionMarketplaceSetFilters,
  actionMarketplaceSetPagination,
  initStateMarketplace,
} from 'store/marketplace';
import { setToModel } from 'utils/other';

function* watchApplySearch() {
  const dashboardFilters = yield* select(selectDashboardFilters);
  const filters = setToModel(initStateMarketplace().filters, {
    diamondShapeIDs: dashboardFilters.diamondShapeIDs,
    sizeRange: dashboardFilters.sizeRange,
    diamondClarityRanks: dashboardFilters.diamondClarityRanks,
    diamondColorRanks: dashboardFilters.diamondColorRanks,
    diamondFinishCutRanks: dashboardFilters.diamondFinishCutRanks,
  });
  yield* put(actionMarketplaceSetFilters(filters));
  yield* put(actionMarketplaceSetPagination({ page: 1 }));
  yield* put(actionMarketplaceGet());
}

export const sagasDashboard = [takeEvery([actionDashboardApply], watchApplySearch)];
