import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { all } from 'modules/typed-saga';
import { PersistMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { reducerLanguages } from 'store/languages';
import { rtkQueryErrorLogger } from 'store/middleware';
import { reducerMyFavorite, sagasMyFavorite } from 'store/my-favorite';
import { apiRtk } from 'utils/service';
import packages from '../../package.json';
import { reducerAccount, sagasAccount } from './auth';
import { reducerDashboard, sagasDashboard } from './dashboard';
import { reducerDiamondImages, sagasDiamondImages } from './diamond-images';
import { reducerDiamondMessages, sagasDiamondMessages } from './diamond-messages';
import { reducerLabels } from './labels';
import { reducerMarketplace, sagasMarketplace } from './marketplace';
import { reducerStockJewelries, sagasStockJewelries } from './stock-jewelries';
import { reducerStocks, sagasStocks } from './stocks';

const VERSION = parseInt(packages.version.replace(/\./gi, ''));

const migrateStore: PersistMigrate = (state) => {
  if (VERSION === state?._persist.version) {
    return Promise.resolve(state);
  } else {
    return Promise.resolve(undefined);
  }
};

const rootReducer = combineReducers({
  account: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'account',
      storage,
      whitelist: ['companyID'],
    },
    reducerAccount,
  ),

  labels: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'labels',
      storage,
      whitelist: ['languageID'],
    },
    reducerLabels,
  ),
  languages: reducerLanguages,
  stocks: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'stocks',
      storage,
      whitelist: ['showedColumns', 'filters'],
    },
    reducerStocks,
  ),
  stockJewelries: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'stockJewelries',
      storage,
      whitelist: ['showedColumns', 'filters'],
    },
    reducerStockJewelries,
  ),
  marketplace: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'marketplace',
      storage,
      whitelist: ['showedColumns', 'filters'],
    },
    reducerMarketplace,
  ),
  myFavorite: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'myFavorite',
      storage,
      whitelist: ['showedColumns', 'filters'],
    },
    reducerMyFavorite,
  ),
  dashboard: reducerDashboard,
  diamondImages: reducerDiamondImages,
  diamondMessages: reducerDiamondMessages,

  [apiRtk.reducerPath]: apiRtk.reducer,
});

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    ...sagasAccount,
    ...sagasStocks,
    ...sagasMarketplace,
    ...sagasDiamondImages,
    ...sagasDiamondMessages,
    ...sagasMyFavorite,
    ...sagasDashboard,
    ...sagasStockJewelries,
  ]);
}

export const appStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(sagaMiddleware)
      .concat(apiRtk.middleware)
      .concat(rtkQueryErrorLogger);
  },
  devTools: process.env.NODE_ENV !== 'production',
});
sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;
export type AppAsyncThunkConfig = {
  state: AppState;
  dispatch: AppDispatch;
  serializedErrorType: Error;
};

export const appPersistor = persistStore(appStore);
