import { createSlice } from '@reduxjs/toolkit';
import { Language } from 'services/parameters';
import { actionLanguagesGet } from './actions';

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: Language[];
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
  };
};

const slice = createSlice({
  name: 'LANGUAGES',
  initialState: initState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionLanguagesGet.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(actionLanguagesGet.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.data = data;
      state.isLoading = false;
      state.isInit = true;
    });
    builder.addCase(actionLanguagesGet.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = error;
      state.isInit = true;
    });
  },
});

export const reducerLanguages = slice.reducer;
