import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StockDiamond } from 'services/stock-diamonds';
import { actionAccountLogout, actionAccountSetCompanyID } from 'store/auth';
import { DiamondFilters, DIAMOND_ORDER_BY, DIAMOND_VIEWS } from 'store/shared';
import { actionStockDelete, actionStockGet } from 'store/stocks/actions';

export const diamondStockShowedColumnsFrozen: (keyof StockDiamond)[] = [
  'stockNumber',
  'entryDate',
  'diamondLocationTitle',
  'diamondLabTitle',
  'diamondShapeTitle',
  'size',
  'diamondColorTitle',
  'diamondClarityTitle',
  'diamondFinishCutTitle',
  'diamondFinishPolishTitle',
  'listPrice',
];

export const diamondStockShowedColumns: (keyof StockDiamond)[] = [
  'directIcePrice',
  'diamondAdvanceBrandTitle',
  'diamondFancyColorSecondTitle',
  'diamondFancyColorIntensityTitle',
  'diamondFinishSymmetryTitle',
  'diamondFluorescenceIntensityColorTitle',
  'diamondFluorescenceIntensityWeightTitle',
  'diamondInclusionBlackTitle',
  'diamondInclusionEyeCleanTitle',
  'diamondInclusionMilkyTitle',
  'diamondInclusionOpenTitle',
  'diamondInclusionWhiteTitle',
  'diamondShadeTitle',
  'diamondTreatmentTypeTitle',
  'diamondAvailabilityTitle',
  'diamondGirdleConditionTitle',
  'diamondCuletConditionTitle',
  'diamondCuletSizeTitle',
  'description',
  'labLocation',
  'city',
  'measurementsLength',
  'measurementsWidth',
  'measurementsDepth',
  'depthPercentage',
  'tablePercentage',
  'crownAngle',
  'crownHeight',
  'pavillionAngle',
  'pavillionDepth',
  'laserInscription',
  'starLength',
  'reportNumber',
  'reportDate',
  'reportComment',
];

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  view: DIAMOND_VIEWS;
  data: StockDiamondItem[];
  pagination: Pagination;
  filters: DiamondFilters;
  showedColumns: (keyof StockDiamond)[];
  orderBy: DIAMOND_ORDER_BY;
}

interface Pagination {
  take: number;
  count: number;
  page: number;
}

export type StockDiamondItem = Pick<
  StockDiamond,
  | 'id'
  | 'stockNumber'
  | 'entryDate'
  | 'diamondLocationTitle'
  | 'diamondLabTitle'
  | 'diamondShapeTitle'
  | 'size'
  | 'diamondColorTitle'
  | 'diamondClarityTitle'
  | 'diamondFinishCutTitle'
  | 'diamondFinishPolishTitle'
  | 'listPrice'
  | 'directIcePrice'
  | 'diamondAdvanceBrandTitle'
  | 'diamondFancyColorSecondTitle'
  | 'diamondFancyColorIntensityTitle'
  | 'diamondFinishSymmetryTitle'
  | 'diamondFluorescenceIntensityColorTitle'
  | 'diamondFluorescenceIntensityWeightTitle'
  | 'diamondInclusionBlackTitle'
  | 'diamondInclusionEyeCleanTitle'
  | 'diamondInclusionMilkyTitle'
  | 'diamondInclusionOpenTitle'
  | 'diamondInclusionWhiteTitle'
  | 'diamondShadeTitle'
  | 'diamondTreatmentTypeTitle'
  | 'diamondAvailabilityTitle'
  | 'diamondGirdleConditionTitle'
  | 'diamondCuletConditionTitle'
  | 'diamondCuletSizeTitle'
  | 'description'
  | 'labLocation'
  | 'city'
  | 'measurementsLength'
  | 'measurementsWidth'
  | 'measurementsDepth'
  | 'depthPercentage'
  | 'tablePercentage'
  | 'crownAngle'
  | 'crownHeight'
  | 'pavillionAngle'
  | 'pavillionDepth'
  | 'laserInscription'
  | 'starLength'
  | 'reportNumber'
  | 'reportDate'
  | 'reportComment'
  | 'diamondShapeIcon'
  | 'mediaPicture'
  | 'mediaReport'
  | 'mediaVideo'
  | 'diamondFancyColorFirstTitle'
>;

export const initStateStock = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    view: DIAMOND_VIEWS.LIST,
    pagination: {
      take: 20,
      count: 0,
      page: 1,
    },
    filters: {
      search: '',
      diamondColorIDs: [],
      diamondShapeIDs: [],
      diamondClarityIDs: [],
      diamondLocationID: '',
      labLocation: '',

      sizeFrom: '',
      sizeTo: '',

      diamondLabID: '',
      diamondFancyColorID: '',
      diamondFinishCutIDs: [],
      diamondFluorescenceIntensityColorID: '',
      diamondFluorescenceIntensityWeightID: '',
      diamondFinishSymmetryID: '',
      diamondFinishPolishID: '',

      diamondToDiamondOvertoneIDs: [],
      diamondFancyColorIntensityID: '',
      diamondFancyColorSecondID: '',
    },
    showedColumns: diamondStockShowedColumnsFrozen,
    orderBy: DIAMOND_ORDER_BY.PRICE_HIGH,
  };
};

const slice = createSlice({
  name: 'STOCKS',
  initialState: initStateStock(),
  reducers: {
    actionStockSetPagination(state, action: PayloadAction<Partial<Pagination>>) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    actionStockSetShowedColumns(state, action: PayloadAction<(keyof StockDiamond)[]>) {
      state.showedColumns = Array.from(
        new Set([...diamondStockShowedColumnsFrozen, ...action.payload]).keys(),
      );
    },
    actionStockSetOrder(state, action: PayloadAction<DIAMOND_ORDER_BY>) {
      state.orderBy = action.payload;
    },
    actionStockSetFilters(state, action: PayloadAction<Partial<DiamondFilters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionStockClearFilters(state) {
      state.filters = initStateStock().filters;
    },
    actionStockSetView(state, action: PayloadAction<DIAMOND_VIEWS>) {
      state.view = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionStockGet.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(actionStockGet.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isInit = true;
      state.data = payload.data.map((item) => ({ ...item, __checked: false }));
      state.pagination.count = payload.count;
    });
    builder.addCase(actionStockGet.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isInit = true;
      state.error = error;
    });
    builder.addCase(actionAccountSetCompanyID, (state) => {
      state.isInit = false;
      state.pagination.page = 1;
    });
    builder.addCase(actionAccountLogout.fulfilled, (state, action) => {
      return initStateStock();
    });

    builder.addMatcher(
      (action) => {
        return [actionStockDelete.pending.match(action)].some((v) => v);
      },
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      (action) => {
        return [actionStockDelete.fulfilled.match(action)].some((v) => v);
      },
      (state) => {
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      (action) => {
        return [actionStockDelete.fulfilled.match(action)].some((v) => v);
      },
      (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      },
    );
  },
});

export const {
  actionStockSetPagination,
  actionStockSetShowedColumns,
  actionStockSetFilters,
  actionStockSetOrder,
  actionStockClearFilters,
  actionStockSetView,
} = slice.actions;
export const reducerStocks = slice.reducer;
