const API = 'StockJewelryImages';

export const API_STOCK_JEWELRY_IMAGES = {
  GET: `${API}/GetAllStockJewelryImagesDynamic`,
  POST: `${API}/CreateStockJewelryImage`,
  PATCH: (data: Pick<IStockJewelryImage, 'id'>) => `${API}/PatchStockJewelryImage/${data.id}`,
  DELETE: (data: Pick<IStockJewelryImage, 'id'>) => `${API}/DeleteStockJewelryImage/${data.id}`,
};
export interface IStockJewelryImage {
  jewelryID: string;
  picture: string;
  rank: number;
  id: string;
}
