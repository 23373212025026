import { apiRtk } from 'utils/service';
import { API_PAGES, BasePage } from './models';

export const apiPages = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getTerms: build.query<BasePage, { languageID: string }>({
        query: ({ languageID }) => {
          return { url: API_PAGES.TERMS, params: { filter: `languageID==${languageID}` } };
        },
      }),
      getPrivacy: build.query<BasePage, { languageID: string }>({
        query: ({ languageID }) => {
          return { url: API_PAGES.PRIVACY, params: { filter: `languageID==${languageID}` } };
        },
      }),
      getAppPage: build.query<BasePage, { languageID: string }>({
        query: ({ languageID }) => {
          return { url: API_PAGES.LAYOUT, params: { filter: `languageID==${languageID}` } };
        },
      }),
    };
  },
});
