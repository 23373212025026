import { useAppSelector } from 'hooks/redux';
import { useMap } from 'hooks/use-map';
import { useTranslate } from 'hooks/use-translate';
import { useMemo } from 'react';
import { apiAnalytics } from 'services/analytics';
import { apiDiamondParameters } from 'services/diamond-parameters';
import { apiJewelryParameters } from 'services/jewelry-parameters';
import { apiStockDiamonds } from 'services/stock-diamonds';
import { selectAccountUserCompanyID } from 'store/auth';
import { ArrayType } from 'utils/types';

type DataType<T> = T extends { data?: infer U } ? U : unknown;

export const useSourceData = <T extends { data?: any[] }>(v: T) => {
  const data = useMemo(() => {
    return (v.data || []) as Exclude<DataType<T>, undefined>;
  }, [v.data]);
  return useMemo(() => ({ ...v, data }), [v, data]);
};
export const useSourceDataMap = <T extends { data: any[] }>(
  v: T,
  mapKey: keyof ArrayType<T['data']>,
) => {
  const map = useMap<ArrayType<T['data']>>(v.data, String(mapKey));
  return useMemo(() => ({ ...v, map }), [v, map]);
};
const useSourceTranslate = <T extends { data: any[] }>(
  v: T,
  translateKey: keyof ArrayType<T['data']>,
) => {
  const { t } = useTranslate();

  const data = useMemo(() => {
    return v.data.map((item) => ({ ...item, title: t(item[translateKey]) }));
  }, [v.data, t, translateKey]);
  return useMemo(() => {
    const { data: _, ...rest } = v;
    return { ...rest, data: data as (ArrayType<T['data']> & { title: string })[] };
  }, [v, data]);
};
export const useSourceSizes = () => {
  const result = useSourceData(apiDiamondParameters.endpoints.getSizes.useQuery());
  const data = useMemo(() => {
    return [
      ...result.data.map(({ fromSize, rank, isActive }) => ({
        id: String(fromSize),
        title: String(fromSize),
        rank,
        isActive,
      })),
      ...result.data.map(({ toSize, rank, isActive }) => ({
        id: String(toSize),
        title: String(toSize),
        rank,
        isActive,
      })),
    ].sort((a, b) => Number(a.id) - Number(b.id));
  }, [result.data]);

  return { ...result, data };
};
export const useSourceAdvanceBrands = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getAdvanceBrand.useQuery()),
    'catalogName',
  );
};
export const useSourceSellerRatings = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getSellerRatings.useQuery()),
    'catalogName',
  );
};
export const useSourceShapes = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getShapes.useQuery()),
    'catalogName',
  );
};
export const useSourceTreatmentTypes = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getTreatmentTypes.useQuery()),
    'catalogName',
  );
};
export const useSourceColors = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getColors.useQuery()),
    'catalogName',
  );
};
export const useSourceFancyColors = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getFancyColors.useQuery()),
    'catalogName',
  );
};
export const useSourceFancyColorIntensities = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getFancyColorIntensities.useQuery()),
    'catalogName',
  );
};
export const useSourceFinishCuts = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getFinishCuts.useQuery()),
    'catalogName',
  );
};
export const useSourceFinishPolishes = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getFinishPolishes.useQuery()),
    'catalogName',
  );
};
export const useSourceFinishSymmetries = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getFinishSymmetries.useQuery()),
    'catalogName',
  );
};
export const useSourceClarity = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getClarity.useQuery()),
    'catalogName',
  );
};
export const useSourceFluorescenceIntensityColors = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getFluorescenceIntensityColors.useQuery()),
    'catalogName',
  );
};
export const useSourceFluorescenceIntensityWeights = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getFluorescenceIntensityWeights.useQuery()),
    'catalogName',
  );
};
export const useSourceGradientReports = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getGradingReports.useQuery()),
    'catalogName',
  );
};
export const useSourceInclusionBlack = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getInclusionBlack.useQuery()),
    'catalogName',
  );
};
export const useSourceInclusionEyeClean = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getInclusionEyeClean.useQuery()),
    'catalogName',
  );
};
export const useSourceInclusionMilky = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getInclusionMilky.useQuery()),
    'catalogName',
  );
};
export const useSourceInclusionOpen = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getInclusionOpen.useQuery()),
    'catalogName',
  );
};
export const useSourceInclusionWhite = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getInclusionWhite.useQuery()),
    'catalogName',
  );
};

export const useSourceLocations = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getLocations.useQuery()),
    'catalogName',
  );
};
export const useSourceMediaTypes = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getMediaTypes.useQuery()),
    'catalogName',
  );
};
export const useSourceShades = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getShades.useQuery()),
    'catalogName',
  );
};
export const useSourceAvailability = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getAvailability.useQuery()),
    'catalogName',
  );
};
export const useSourceStockDiamonds = () => {
  const agencyID = useAppSelector(selectAccountUserCompanyID);
  return useSourceData(
    apiStockDiamonds.endpoints.getSourceStockDiamonds.useQuery(
      { agencyID: agencyID || '' },
      { skip: !agencyID },
    ),
  );
};
export const useSourceGirdleCondition = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getGirdleCondition.useQuery()),
    'catalogName',
  );
};
export const useSourceGirdleSize = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getGirdleSize.useQuery()),
    'catalogName',
  );
};

export const useSourceCuletCondition = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getCuletCondition.useQuery()),
    'catalogName',
  );
};
export const useSourceCuletSize = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getCuletSize.useQuery()),
    'catalogName',
  );
};
export const useSourceLab = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getLab.useQuery()),
    'catalogName',
  );
};

export const useSourceKeySymbol = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getKeySymbol.useQuery()),
    'catalogName',
  );
};
export const useSourceOvertone = () => {
  return useSourceTranslate(
    useSourceData(apiDiamondParameters.endpoints.getOvertone.useQuery()),
    'catalogName',
  );
};
export const useSourceJewelryCategories = () => {
  return useSourceTranslate(
    useSourceData(apiJewelryParameters.useGetJewelryCategoriesQuery()),
    'catalogName',
  );
};
export const useSourceJewelrySubCategories = () => {
  return useSourceTranslate(
    useSourceData(apiJewelryParameters.useGetJewelrySubCategoriesQuery()),
    'catalogName',
  );
};
export const useSourceJewelryMetalColors = () => {
  return useSourceTranslate(
    useSourceData(apiJewelryParameters.useGetJewelryMetalColorsQuery()),
    'catalogName',
  );
};
export const useSourceJewelryMetalKarats = () => {
  return useSourceTranslate(
    useSourceData(apiJewelryParameters.useGetJewelryMetalKaratsQuery()),
    'catalogName',
  );
};
export const useSourceJewelryRingSizes = () => {
  return useSourceTranslate(
    useSourceData(apiJewelryParameters.useGetJewelryRingSizesQuery()),
    'catalogName',
  );
};
export const useSourceAnalyticFavorites = () => {
  const agencyID = useAppSelector(selectAccountUserCompanyID);
  return useSourceData(
    apiAnalytics.endpoints.getFavorites.useQuery(
      { agencyID: agencyID || '' },
      { skip: !agencyID, refetchOnMountOrArgChange: true },
    ),
  );
};
export const useSourceAnalyticFavoritesKpi = () => {
  const agencyID = useAppSelector(selectAccountUserCompanyID);
  return useSourceData(
    apiAnalytics.endpoints.getFavoritesKpi.useQuery(
      { agencyID: agencyID || '' },
      { skip: !agencyID, refetchOnMountOrArgChange: true },
    ),
  );
};
export const useSourceAnalyticKpi = () => {
  const agencyID = useAppSelector(selectAccountUserCompanyID);
  return apiAnalytics.endpoints.getDiamondsKpi.useQuery(
    { agencyID: agencyID || '' },
    { skip: !agencyID, refetchOnMountOrArgChange: true },
  );
};
export const useSourceAnalyticStockKpi = () => {
  const agencyID = useAppSelector(selectAccountUserCompanyID);
  return useSourceTranslate(
    useSourceData(
      apiAnalytics.endpoints.getDiamondsStockKpi.useQuery(
        { agencyID: agencyID || '' },
        { skip: !agencyID, refetchOnMountOrArgChange: true },
      ),
    ),
    'diamondShapeTitle',
  );
};
