const API = 'Accounts';

export const API_AUTH = {
  GENERATE_PASSWORD: `${API}/GeneratePassword`,
  LOGIN: `${API}/LoginWithCode`,
  LOGOUT: `${API}/Logout`,
  REFRESH_TOKEN: `${API}/RefreshToken`,
  GET_CURRENT_USER: `${API}/GetCurrentAppUser`,
  PATCH_CURRENT_USER: `${API}/PatchCurrentUser`,
};

export interface CurrentAppUser {
  appUserID: string;
  languageID: string;
  firstName: string;
  lastName: string;
  fullName: string;
  userPhoto: string;
  email: string;
  mobilePhone: string;
  companies: Company[];
}
export interface Company {
  agencyToUserAgencyProfileID: string;
  agencyID: string;
  agencyName: string;
  description: string;
  logo: string;
  position: string;
  maxUsers: number;
  favorites: number;
}

export interface JWT {
  jwt: {
    token: string;
    refreshToken: string;
  };
  expires: string;
}
