import { useRawScript } from 'hooks';
import React, { memo, PropsWithChildren, useEffect, useReducer } from 'react';

type Value = string | null | undefined;

class InitState {
  title = '';

  generalBodyScripts?: Value = '';
  generalFooterScripts?: Value = '';
  generalHeaderScripts?: Value = '';
  googleAnalyticScripts?: Value = '';

  metaTitle?: Value = '';
  metaDescription?: Value = '';
  metaKeywords?: Value = '';
}

export const MetaContext = React.createContext<((action: Actions) => void) | null>(null);

type Actions = { type: 'set'; payload: Partial<InitState> };
const reducer = (state: InitState, action: Actions): InitState => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default: {
      return state;
    }
  }
};

export const MetaProvider = memo<PropsWithChildren>(({ children }) => {
  const [state, dispatch] = useReducer(reducer, new InitState());

  const { generalBodyScripts, generalFooterScripts, generalHeaderScripts, googleAnalyticScripts } =
    state;

  useRawScript(generalHeaderScripts, { parent: document.head });
  useRawScript(googleAnalyticScripts, { parent: document.head });
  useRawScript(generalBodyScripts, { parent: document.body, insert: 'first' });
  useRawScript(generalFooterScripts, { parent: document.body });

  const { metaTitle, metaDescription, metaKeywords } = state;

  useEffect(() => {
    if (!metaTitle) {
      return;
    }
    document.title = metaTitle;
  }, [metaTitle]);

  useEffect(() => {
    if (!metaDescription) {
      return;
    }
    const el = document.querySelector("meta[name='description']");
    el?.setAttribute('content', metaDescription);
  }, [metaDescription]);

  useEffect(() => {
    if (!metaKeywords) {
      return;
    }
    const el = document.querySelector("meta[name='keywords']");
    el?.setAttribute('content', metaKeywords);
  }, [metaKeywords]);

  return <MetaContext.Provider value={dispatch}>{children}</MetaContext.Provider>;
});
