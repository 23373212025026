import { JEWELRY_CATEGORY_TYPE } from 'services/jewelry-parameters/models';
import { ServiceStockJewelryImage } from 'services/stock-jawerly-images';
import { equals, makeFilter } from 'utils/dynamic';
import { keepToModel } from 'utils/other';
import {
  apiApp,
  apiRtk,
  DynamicParams,
  DynamicResult,
  prepareRecords,
  RTK_TAGS,
  transformResponseDynamicItem,
  uploadFile,
} from 'utils/service';
import { API_STOCK_JEWELRIES, IStockJewelry, StockJewelry } from './models';

export * from './models';

interface GetParams extends DynamicParams {
  agencyID: string;
}

const prepareValueByType = (data: Partial<StockJewelry>): Partial<StockJewelry> => {
  switch (data.jewelryCategoryType) {
    case JEWELRY_CATEGORY_TYPE.displayNone: {
      return { ...data, jewelryRingSizeID: null, length: '' };
    }
    case JEWELRY_CATEGORY_TYPE.displaySize: {
      return { ...data, length: '' };
    }
    case JEWELRY_CATEGORY_TYPE.displayLength: {
      return { ...data, jewelryRingSizeID: null };
    }
  }
  return data;
};

class Service {
  private prepareValue(data: Partial<StockJewelry>) {
    const preparedValue = keepToModel(new StockJewelry(), {
      ...prepareValueByType(data),
      jewelryCategoryType: undefined,
      id: undefined,
    });
    return preparedValue;
  }
  async getAllDynamic<T = StockJewelry, P extends GetParams = GetParams>(params: P) {
    return apiApp.get<DynamicResult<T, P>>(API_STOCK_JEWELRIES.GET, { params });
  }
  async post(data: StockJewelry) {
    let { stockJewelryImages, mediaVideo, mediaReport, ...rest } = data;

    let resultMedia = await Promise.all([
      uploadFile(mediaVideo, null),
      uploadFile(mediaReport, null),
    ]);

    const media = {
      mediaVideo: resultMedia[0],
      mediaReport: resultMedia[1],
    };

    const result = await apiApp.post<string>(
      API_STOCK_JEWELRIES.POST,
      this.prepareValue({ ...rest, ...media }),
    );

    await Promise.all(
      stockJewelryImages.map((item) => {
        const { rank, value } = item;
        return ServiceStockJewelryImage.post({ rank, picture: value, jewelryID: result.data });
      }),
    );

    return result;
  }
  async patch(dataNew: StockJewelry, dataOld: StockJewelry) {
    let resultMedia = await Promise.all([
      uploadFile(dataNew.mediaVideo, dataOld.mediaVideo),
      uploadFile(dataNew.mediaReport, dataOld.mediaReport),
    ]);

    const { stockJewelryImages, ...rest } = dataNew;

    const media = {
      mediaVideo: resultMedia[0],
      mediaReport: resultMedia[1],
    };

    const result = await apiApp.patch<string>(
      API_STOCK_JEWELRIES.PATCH(dataNew),
      this.prepareValue({ ...rest, ...media, agencyID: undefined }),
    );

    const { postItems, deleteItems, patchItems } = prepareRecords(
      dataNew.stockJewelryImages,
      dataOld.stockJewelryImages,
      'id',
    );
    await Promise.all([
      ...postItems.map(({ rank, value }) =>
        ServiceStockJewelryImage.post({ rank, picture: value, jewelryID: dataNew.id }),
      ),
      ...patchItems.map(({ id, rank }) => ServiceStockJewelryImage.patch({ id: id, rank })),
      ...deleteItems.map(({ id }) => ServiceStockJewelryImage.delete({ id })),
    ]);

    return result;
  }
  async delete(data: Pick<IStockJewelry, 'id'>) {
    return apiApp.delete(API_STOCK_JEWELRIES.DELETE(data));
  }
}

export const ServiceStockJewelries = new Service();

export const apiStockJewelries = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getStockJewelry: build.query<IStockJewelry, { agencyID: string; id: string }>({
      query: ({ agencyID, id }) => ({
        url: API_STOCK_JEWELRIES.GET,
        params: {
          agencyID,
          take: 1,
          filter: makeFilter<IStockJewelry>('id', id, equals),
        },
      }),
      transformResponse: transformResponseDynamicItem,
      providesTags: (res, error, arg) => [{ type: RTK_TAGS.STOCK_JEWELRIES, id: arg.id }],
    }),
    postStockJewelry: build.mutation<string, StockJewelry>({
      queryFn: async (data) => {
        try {
          const { data: id } = await ServiceStockJewelries.post(data);
          return { data: id };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    patchStockJewelry: build.mutation<string, { dataNew: StockJewelry; dataOld: StockJewelry }>({
      queryFn: async ({ dataNew, dataOld }) => {
        try {
          const { data: id } = await ServiceStockJewelries.patch(dataNew, dataOld);
          return { data: id };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, error, arg) => [
        { type: RTK_TAGS.STOCK_JEWELRIES, id: arg.dataNew.id },
      ],
    }),
    deleteStockJewelry: build.mutation<string, Pick<StockJewelry, 'id'>>({
      queryFn: async (data) => {
        try {
          const { data: id } = await ServiceStockJewelries.delete(data);
          return { data: id };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
