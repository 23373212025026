import { Loading } from 'components/loading';
import { APP_ROUTES } from 'configs';
import { EmptyLayout, PrivateLayout } from 'layouts';
import { LoginLayout } from 'layouts/login';
import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RequireAuth } from 'router/requred-auth';
import { RequireCompany } from 'router/requred-company';

const HomePage = React.lazy(() => import('pages/home'));

const LoginPage = React.lazy(() => import('pages/login'));
const LoginCodePage = React.lazy(() => import('pages/login-code'));
const LoginCompanyPage = React.lazy(() => import('pages/login-company'));

const MarketPlacePage = React.lazy(() => import('pages/marketplace'));
const StocksPage = React.lazy(() => import('pages/stocks'));
const StockJewelriesPage = React.lazy(() => import('pages/stock-jewelries'));
const StockJewelryItemPage = React.lazy(() => import('pages/stock-jewelry-item'));
const MyFavoritePage = React.lazy(() => import('pages/my-favorites'));
const StockItemPage = React.lazy(() => import('pages/stock-item'));

const NotFoundPage = React.lazy(() => import('pages/not-found'));

export const routes: RouteObject[] = [
  {
    element: <PrivateLayout />,
    children: [
      {
        path: APP_ROUTES.HOME.path,
        element: (
          <RequireAuth>
            <RequireCompany>
              <React.Suspense fallback={<Loading />}>
                <HomePage />
              </React.Suspense>
            </RequireCompany>
          </RequireAuth>
        ),
      },
      {
        path: APP_ROUTES.MY_STOCK.path,
        element: (
          <RequireAuth>
            <RequireCompany>
              <React.Suspense fallback={<Loading />}>
                <StocksPage />
              </React.Suspense>
            </RequireCompany>
          </RequireAuth>
        ),
      },
      {
        path: APP_ROUTES.MY_STOCK_JEWELRIES.path,
        element: (
          <RequireAuth>
            <RequireCompany>
              <React.Suspense fallback={<Loading />}>
                <StockJewelriesPage />
              </React.Suspense>
            </RequireCompany>
          </RequireAuth>
        ),
      },
      {
        path: APP_ROUTES.MY_FAVORITE.path,
        element: (
          <RequireAuth>
            <RequireCompany>
              <React.Suspense fallback={<Loading />}>
                <MyFavoritePage />
              </React.Suspense>
            </RequireCompany>
          </RequireAuth>
        ),
      },
      {
        path: APP_ROUTES.MY_STOCK_ITEM.path,
        element: (
          <RequireAuth>
            <RequireCompany>
              <React.Suspense fallback={<Loading />}>
                <StockItemPage />
              </React.Suspense>
            </RequireCompany>
          </RequireAuth>
        ),
      },
      {
        path: APP_ROUTES.MY_STOCK_JEWELRY_ITEM.path,
        element: (
          <RequireAuth>
            <RequireCompany>
              <React.Suspense fallback={<Loading />}>
                <StockJewelryItemPage />
              </React.Suspense>
            </RequireCompany>
          </RequireAuth>
        ),
      },
      {
        path: APP_ROUTES.MARKETPLACE.path,
        element: (
          <RequireAuth>
            <RequireCompany>
              <React.Suspense fallback={<Loading />}>
                <MarketPlacePage />
              </React.Suspense>
            </RequireCompany>
          </RequireAuth>
        ),
      },
    ],
  },
  {
    element: <LoginLayout />,
    children: [
      {
        path: APP_ROUTES.LOGIN.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <LoginPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN_CODE.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <LoginCodePage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN_COMPANY.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <LoginCompanyPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <EmptyLayout />,
    children: [
      {
        path: '*',
        element: (
          <React.Suspense fallback={<Loading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
    ],
  },
];
