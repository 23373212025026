import { createSelector } from '@reduxjs/toolkit';
import { APP_CURRENCY, APP_PERCENT } from 'configs';
import { AppState } from '../index';

const selectState = (state: AppState) => state.account;
export const selectAccountUser = createSelector(selectState, ({ user }) => {
  return user;
});

export const selectAccountUserCompanyID = createSelector(selectState, ({ companyID }) => {
  return companyID;
});
export const selectAccountUserCompanies = createSelector(selectState, ({ user }) => {
  return user?.companies || [];
});
export const selectAccountUserCompany = createSelector(
  selectAccountUserCompanyID,
  selectAccountUserCompanies,
  (companyID, companies) => {
    return companies.find(({ agencyID }) => agencyID === companyID);
  },
);
export const selectAccountFavorites = createSelector(selectAccountUserCompany, (company) => {
  return company?.favorites || 0;
});

export const selectAccountState = createSelector(selectState, ({ isLoading, isInit, error }) => {
  return { isLoading, isInit, error };
});

export const selectAccountCurrencySymbol = () => {
  return APP_CURRENCY;
};
export const selectAccountPercentSymbol = () => {
  return APP_PERCENT;
};
