import { useCallback, useMemo } from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { debounce, fieldToLabelKey } from 'utils/other';
import { useTranslate } from './use-translate';

interface UseFormControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  onWasChanged?: (name: TName) => void;
}

export const useFormController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: UseFormControllerProps<TFieldValues, TName>,
) => {
  const { onWasChanged, ...rest } = props;
  const { t } = useTranslate();
  const res = useController(rest);
  const debounceChange = useMemo(() => {
    return onWasChanged ? debounce(onWasChanged, 1000) : undefined;
  }, [onWasChanged]);
  const originOnChange = res.field.onChange;
  const onChangeWrap = useCallback(
    (...event: any[]) => {
      originOnChange(...event);
      debounceChange && debounceChange(rest.name);
    },
    [rest.name, originOnChange, debounceChange],
  );
  const label = useMemo(() => {
    return t(fieldToLabelKey(rest.name));
  }, [rest.name, t]);
  const error = res.fieldState.error;
  const helperText = useMemo(() => {
    return error && error.message ? t(error.message) : undefined;
  }, [error, t]);

  const {
    field: { ref, ...field },
  } = res;

  return {
    ...res,
    field: {
      ...field,
      onChange: onChangeWrap,
      label,
      helperText,
      error: Boolean(error),
    },
  };
};
