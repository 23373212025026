import axios from 'axios';
import { apiApp, BASE_URL } from 'utils/service';
import { API_AUTH, CurrentAppUser, JWT } from './models';

export * from './models';

class RefreshTokenError extends Error {
  response: { status: number };

  constructor(message: string, status: number) {
    super(message);
    this.response = { status };
  }
}

class Service {
  async refreshToken(p: { token?: string; refreshToken?: string }) {
    const urlPrefix = `${BASE_URL}/api/`;
    const { data } = await axios.post<JWT['jwt']>(`${urlPrefix}${API_AUTH.REFRESH_TOKEN}`, p);

    const result = await fetch(`${urlPrefix}${API_AUTH.GET_CURRENT_USER}`, {
      headers: {
        Authorization: 'Bearer ' + data.token,
      },
    });

    if (result.ok) {
      return { data };
    }

    throw new RefreshTokenError('refresh-token', result.status);
  }

  async getCurrentUser() {
    return apiApp.get<CurrentAppUser>(API_AUTH.GET_CURRENT_USER);
  }

  async generatePassword(data: { email: string }) {
    return apiApp.post(API_AUTH.GENERATE_PASSWORD, data);
  }

  async sendCode(data: { email: string; code: string }) {
    return apiApp.post<JWT>(API_AUTH.LOGIN, data);
  }

  async logout() {
    return apiApp.post(API_AUTH.LOGOUT);
  }
}

export const ServiceAccounts = new Service();
