import { call, put, select, takeEvery, takeLatest } from 'modules/typed-saga';
import { ServiceDiamondMessages } from 'services/diamond-messages';
import { selectAccountUserCompanyID } from 'store/auth';
import { orderBy } from 'utils/dynamic';
import { workerErrorNotifySaga, workerErrorNotifyThunk } from 'utils/sagas';
import { parseErrorData } from 'utils/service';
import { actionDiamondMessagesCreate, actionDiamondMessagesDelete } from './actions';
import { selectDiamondMessages } from './selectors';
import { actionDiamondMessagesGet } from './slice';

function* fetchMessages(action: ReturnType<typeof actionDiamondMessagesGet.pending>) {
  const agencyID = yield* select(selectAccountUserCompanyID);
  const { diamondID } = action.payload;
  const {
    pagination: { skip, take },
  } = yield* select(selectDiamondMessages, diamondID);

  try {
    if (!agencyID) {
      throw new Error('unexpected-behaviour');
    }
    const {
      data: { value, count },
    } = yield* call(ServiceDiamondMessages.getAllDynamic, {
      diamondID,
      agencyID,
      orderBy: orderBy('entryDate', 'desc'),
      skip,
      take,
      count: true,
    });
    yield* put(actionDiamondMessagesGet.fulfilled({ diamondID, data: value, count }));
  } catch (e: any) {
    yield* put(actionDiamondMessagesGet.rejected({ diamondID, error: parseErrorData(e) }));
  }
}

export const sagasDiamondMessages = [
  takeLatest(actionDiamondMessagesGet.pending, fetchMessages),
  takeEvery(actionDiamondMessagesGet.rejected, workerErrorNotifySaga),
  takeEvery(
    [actionDiamondMessagesCreate.rejected, actionDiamondMessagesDelete.rejected],
    workerErrorNotifyThunk,
  ),
];
