import { apiApp, DynamicResult } from 'utils/service';
import { API_LABELS, Label } from './models';

export * from './models';

class Service {
  async getDefaultLanguageID() {
    return apiApp.get<string>(API_LABELS.GET_DEFAULT_LANGUAGE);
  }

  async getLabels(languageID: string) {
    const params = {
      filter: `languageID == "${languageID}"`,
    };
    return apiApp.get<DynamicResult<Label, typeof params>>(API_LABELS.GET_LABELS, { params });
  }
}

export const ServiceLabels = new Service();
