const API = 'Parameters';

export const API_PARAMETERS = {
  GET_LANGUAGES: `${API}/GetLanguagesDynamic`,
  GET_FULL_STORY_CODE: `${API}/GetFullStoryCode`,
};

export interface Language {
  id: string;
  title: string;
  culture: string;
  direction: number;
  importName: string;
  isoCode: string;
  icon: string;
  rank: number;
  isRTL: boolean;
}
