import { Loading } from 'components/loading';
import { useAppDispatch } from 'hooks/redux';
import React, { PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { actionAccountGetUser, selectAccountState } from 'store/auth';

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isInit, isLoading } = useSelector(selectAccountState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isInit) {
      dispatch(actionAccountGetUser());
    }
  }, [isInit, dispatch]);

  return (
    <>
      {isInit && children}
      {isLoading && <Loading />}
    </>
  );
};
