import { apiRtk, transformResponseDynamic } from 'utils/service';
import { API_DIAMOND_PARAMETERS, BaseDiamondParameter, DiamondShape, DiamondSize } from './models';

const axiosParams = {
  params: {
    orderBy: 'rank asc',
  },
};

export const apiDiamondParameters = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getAdvanceBrand: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_ADVANCE_BRAND, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getLab: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_LAB, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getKeySymbol: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_KEY_SYMBOL, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getOvertone: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_OVERTONE, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getAvailability: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_AVAILABILITY, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getCuletCondition: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_CULET_CONDITION, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getCuletSize: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_CULET_SIZE, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getGirdleCondition: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_GIRDLE_CONDITION, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getGirdleSize: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_GIRDLE_SIZE, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getClarity: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_CLARITY, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getColors: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_COLORS, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getFancyColors: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_FANCY_COLORS, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getFancyColorIntensities: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_FANCY_COLOR_INTENSITIES, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getFinishCuts: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_FINISH_CUTS, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getFinishPolishes: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_FINISH_POLISHES, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getFinishSymmetries: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_FINISH_SYMMETRIES, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getFluorescenceIntensityColors: build.query<BaseDiamondParameter[], void>({
        query() {
          return {
            url: API_DIAMOND_PARAMETERS.GET_FLUORESCENCE_INTENSITY_COLORS,
            params: axiosParams,
          };
        },
        transformResponse: transformResponseDynamic,
      }),
      getFluorescenceIntensityWeights: build.query<BaseDiamondParameter[], void>({
        query() {
          return {
            url: API_DIAMOND_PARAMETERS.GET_FLUORESCENCE_INTENSITY_WEIGHTS,
            params: axiosParams,
          };
        },
        transformResponse: transformResponseDynamic,
      }),
      getGradingReports: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_GRADING_REPORTS, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getInclusionBlack: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_INCLUSION_BLACK, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getInclusionEyeClean: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_INCLUSION_EYE_CLEAN, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getInclusionMilky: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_INCLUSION_MILKY, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getInclusionOpen: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_INCLUSION_OPEN, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getInclusionWhite: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_INCLUSION_WHITE, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getLocations: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_LOCATIONS, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getMediaTypes: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_MEDIA_TYPES, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getSellerRatings: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_SELLER_RATINGS, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getShades: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_SHADES, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getShapes: build.query<DiamondShape[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_SHAPES, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getSizes: build.query<DiamondSize[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_SIZES, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
      getTreatmentTypes: build.query<BaseDiamondParameter[], void>({
        query() {
          return { url: API_DIAMOND_PARAMETERS.GET_TREATMENT_TYPES, params: axiosParams };
        },
        transformResponse: transformResponseDynamic,
      }),
    };
  },
});
