import { put, takeLatest } from 'modules/typed-saga';
import { actionAccountGetUser } from 'store/auth/actions';
import { actionMyFavoriteAddFavorite, actionMyFavoriteRemoveFavorite } from 'store/my-favorite';

function* watchFavorite() {
  yield* put(actionAccountGetUser());
}

export const sagasAccount = [
  takeLatest(
    [actionMyFavoriteAddFavorite.fulfilled, actionMyFavoriteRemoveFavorite.fulfilled],
    watchFavorite,
  ),
];
