import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DiamondImage } from 'services/diamond-images';
import { actionAccountLogout } from 'store/auth';
import { actionDiamondImagesGet } from './actions';

export interface DiamondImageFilters {
  diamondShapeID: string;
  diamondClarityID: string;
  diamondColorID: string;
  diamondFancyColorID: string;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: DiamondImage[];
  pagination: Pagination;
  filters: DiamondImageFilters;
}

interface Pagination {
  take: number;
  count: number;
  page: number;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    pagination: {
      take: 10,
      count: 0,
      page: 1,
    },
    filters: {
      diamondShapeID: '',
      diamondClarityID: '',
      diamondColorID: '',
      diamondFancyColorID: '',
    },
  };
};
const slice = createSlice({
  name: 'DIAMOND_IMAGES',
  initialState: initState(),
  reducers: {
    actionDiamondImagesSetPagination(state, action: PayloadAction<Partial<Pagination>>) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    actionDiamondImagesSetFilters(state, action: PayloadAction<Partial<DiamondImageFilters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionDiamondImagesReset() {
      return initState();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionDiamondImagesGet.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(actionDiamondImagesGet.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isInit = true;
      state.data = payload.data.map((item) => ({ ...item, __checked: false }));
      state.pagination.count = payload.count;
    });
    builder.addCase(actionDiamondImagesGet.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isInit = true;
      state.error = error;
    });
    builder.addCase(actionAccountLogout.fulfilled, () => {
      return initState();
    });
  },
});

export const {
  actionDiamondImagesSetPagination,
  actionDiamondImagesSetFilters,
  actionDiamondImagesReset,
} = slice.actions;
export const reducerDiamondImages = slice.reducer;
