import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useCallback, useRef } from 'react';
import { AppState } from 'store';

type Pagination = {
  page: number;
  take: number;
};

interface Options<T extends Pagination> {
  selector: (state: AppState) => T;
  actionChange: (value: Partial<T>) => any;
  actionGet: () => any;
}

export const useDataGridPagination = <T extends Pagination>(options: Options<T>) => {
  const { selector, actionChange, actionGet } = options;
  const scrollRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const pagination = useAppSelector(selector);
  const onChangePage = useCallback(
    async (props: Partial<T>) => {
      dispatch(actionChange(props));
      await dispatch(actionGet());
      scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [dispatch, actionChange, actionGet],
  );
  const onChangeTake = useCallback(
    (props: Partial<T>) => {
      dispatch(actionChange({ ...props, page: 1 }));
      dispatch(actionGet());
    },
    [dispatch, actionChange, actionGet],
  );

  return { ...pagination, onChangePage, onChangeTake, scrollRef };
};
