import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useCallback } from 'react';
import { actionLabelsSetLanguage, selectLanguageID } from 'store/labels';
import { selectLanguagesState } from 'store/languages';

export const useLanguages = () => {
  const dispatch = useAppDispatch();
  const source = useAppSelector(selectLanguagesState);
  const languageID = useAppSelector(selectLanguageID);

  const onChange = useCallback(
    (languageID: string) => {
      dispatch(actionLabelsSetLanguage({ languageID }));
    },
    [dispatch],
  );

  return { languageID, source, onChange };
};
