import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DiamondMessage } from 'services/diamond-messages';
import { actionAccountLogout } from 'store/auth';
import {
  actionDiamondMessagesCreate,
  actionDiamondMessagesDelete,
} from 'store/diamond-messages/actions';
import { setToModel } from 'utils/other';
import { combineAction } from 'utils/sagas';

const defaultPagination = { take: 10, page: 1, count: 0 };

export class StateDiamondMessageItem {
  error: null | Error = null;
  isLoading: boolean = false;
  isInit: boolean = false;
  data: DiamondMessage[] = [];
  pagination: Pagination = defaultPagination;
}

interface Pagination {
  take: number;
  count: number;
  page: number;
}

interface State {
  [diamondID: string]: StateDiamondMessageItem;
}

const initState: State = {};

const slice = createSlice({
  name: 'DIAMOND_MESSAGES',
  initialState: initState,
  reducers: {
    actionDiamondMessagesSetPagination(
      state,
      action: PayloadAction<{ diamondID: string; data: Partial<Pagination> }>,
    ) {
      const { diamondID, data } = action.payload;
      const item = state[diamondID];

      if (item) {
        item.pagination = { ...item.pagination, ...data };
      }
    },
    getMessagesPending(state, action: PayloadAction<{ diamondID: string }>) {
      const { diamondID } = action.payload;
      state[diamondID] = setToModel(new StateDiamondMessageItem(), {
        ...state[diamondID],
        isLoading: true,
        error: null,
      });
    },
    getMessagesFulfilled(
      state,
      action: PayloadAction<{ diamondID: string; data: DiamondMessage[]; count: number }>,
    ) {
      const { diamondID, data, count } = action.payload;
      const item = state[diamondID];
      if (item) {
        item.data = item.isInit ? [...item.data, ...data] : data;
        item.isInit = true;
        item.isLoading = false;
        item.pagination.count = count;
      }
    },
    getMessagesRejected(state, action: PayloadAction<{ diamondID: string; error: Error }>) {
      const { diamondID, error } = action.payload;
      const item = state[diamondID];

      if (item) {
        item.error = error;
        item.isInit = true;
        item.isLoading = false;
        item.pagination.page = Math.max(1, item.pagination.page - 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionAccountLogout.fulfilled, () => {
      return initState;
    });
    builder.addCase(actionDiamondMessagesDelete.fulfilled, (state, action) => {
      const { diamondID, messageID } = action.payload;
      const item = state[diamondID];

      if (item) {
        item.isInit = false;
        item.pagination = defaultPagination;
        item.data = item.data.filter(({ id }) => id !== messageID);
      }
    });
    builder.addCase(actionDiamondMessagesCreate.fulfilled, (state, action) => {
      const { diamondID } = action.payload;
      const item = state[diamondID];

      if (item) {
        item.isInit = false;
        item.pagination = defaultPagination;
      }
    });
  },
});

const actions = slice.actions;

export const { actionDiamondMessagesSetPagination } = actions;

export const actionDiamondMessagesGet = combineAction(
  actions.getMessagesPending,
  actions.getMessagesFulfilled,
  actions.getMessagesRejected,
);
export const reducerDiamondMessages = slice.reducer;
