import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const Loading: React.FC = () => {
  return (
    <Box
      position={'absolute'}
      top={0}
      right={0}
      bottom={0}
      left={0}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <CircularProgress />
    </Box>
  );
};
