import { Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { APP_ROUTES } from 'configs';
import { useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import style from './index.module.scss';

const TAB_ITEMS = [
  {
    id: 1,
    pathname: [APP_ROUTES.HOME.path],
    labelKey: 'dashboard',
  },
  {
    id: 2,
    pathname: [APP_ROUTES.MY_STOCK.path, APP_ROUTES.MY_STOCK_ITEM.path],
    labelKey: 'my-stock',
  },
  {
    id: 3,
    pathname: [APP_ROUTES.MY_STOCK_JEWELRIES.path],
    labelKey: 'my-jewelries-stock',
  },
  {
    id: 4,
    pathname: [APP_ROUTES.MARKETPLACE.path],
    labelKey: 'marketplace',
  },
];

interface Props {
  className?: string;
  isVertical?: boolean;
  onChanged?: () => void;
}

export const HeaderNavigation: React.FC<Props> = ({ className, isVertical, onChanged }) => {
  const { t } = useTranslate();
  const { pathname: locationPathname } = useLocation();
  const value = useMemo(() => {
    const item = TAB_ITEMS.find(({ pathname }) =>
      pathname.some((path) => Boolean(matchPath(path, locationPathname))),
    );
    return item ? item.id : false;
  }, [locationPathname]);
  return (
    <div className={clsx(style.wrap, className)}>
      <Tabs
        value={value}
        classes={{ indicator: clsx(style.indicator, isVertical && style.indicatorVertical) }}
        orientation={isVertical ? 'vertical' : 'horizontal'}
      >
        {TAB_ITEMS.map(({ pathname, labelKey, id }) => {
          return (
            <Tab
              key={id}
              label={t(labelKey)}
              value={id}
              component={Link}
              to={pathname[0]}
              onClick={onChanged}
              classes={{
                root: clsx(style.item, isVertical && style.itemVertical),
              }}
            />
          );
        })}
      </Tabs>
    </div>
  );
};
