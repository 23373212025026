import {
  API_DIAMOND_MESSAGES,
  DiamondMessage,
  DiamondMessagePost,
} from 'services/diamond-messages/models';
import { apiApp, DynamicParams, DynamicResult } from 'utils/service';

export * from './models';

interface GetParams extends DynamicParams {
  agencyID: string;
  diamondID: string;
  count: true;
}

class Service {
  async getAllDynamic<P extends GetParams>(params: P) {
    return apiApp.get<DynamicResult<DiamondMessage, typeof params>>(API_DIAMOND_MESSAGES.GET, {
      params,
    });
  }
  async post(data: DiamondMessagePost) {
    return apiApp.post<DiamondMessage>(API_DIAMOND_MESSAGES.POST, {
      ...data,
      entryDate: new Date().toISOString(),
    });
  }
  async delete(data: { messageID: string; agencyID: string }) {
    const { messageID, agencyID } = data;
    return apiApp.delete(API_DIAMOND_MESSAGES.DELETE({ id: messageID, agencyID }));
  }
}

export const ServiceDiamondMessages = new Service();
