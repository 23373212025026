const chunkPrice = (v: string | number, count = 3) => {
  const result: string[] = [];
  const value = String(v);

  for (let i = value.length; i > 0; i -= count) {
    result.unshift(value.substring(i - count, i));
  }

  return result;
};

export const formatPrice = (v: string | number | null | undefined, divider: string = ',') => {
  if (!v) return v;

  const [first, ...rest] = String(v).split('.');

  return `${chunkPrice(first).join(divider)}${rest}`;
};
