import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentAppUser } from 'services/accounts';
import { actionAccountGetUser, actionAccountLogout } from 'store/auth/actions';

interface InitState {
  isInit: boolean;
  isLoading: boolean;
  error: null | Error;
  user: null | CurrentAppUser;
  companyID: null | string;
}

const initStateAccount = (): InitState => {
  return {
    isInit: false,
    isLoading: false,
    error: null,
    user: null,
    companyID: null,
  };
};

const slice = createSlice({
  name: 'ACCOUNT',
  initialState: initStateAccount(),
  reducers: {
    actionAccountSetCompanyID(state, action: PayloadAction<string>) {
      state.companyID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionAccountGetUser.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(actionAccountGetUser.fulfilled, (state, action) => {
      const user = action.payload;
      const companies = user.companies;

      const company =
        companies.find(({ agencyID }) => agencyID === state.companyID) || companies[0];

      state.isLoading = false;
      state.isInit = true;
      state.user = user;
      state.companyID = company.agencyID;
    });
    builder.addCase(actionAccountGetUser.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = error;
      state.isInit = true;
    });

    builder.addCase(actionAccountLogout.fulfilled, (state, action) => {
      return initStateAccount();
    });
  },
});
export const { actionAccountSetCompanyID } = slice.actions;
export const reducerAccount = slice.reducer;
