import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { apiStockJewelries, IStockJewelry } from 'services/stock-jawelries';
import { actionAccountLogout, actionAccountSetCompanyID } from 'store/auth';
import { DiamondFilters, DIAMOND_ORDER_BY, DIAMOND_VIEWS } from 'store/shared';
import { actionStockJewelriesGet } from './actions';

type GridStockJewelryColumns = Pick<
  IStockJewelry,
  | 'directIcePrice'
  | 'description'
  | 'certificateDiamondStockNumber'
  | 'certificateDiamondFinishCutID'
  | 'certificateDiamondColorID'
  | 'certificateDiamondClarityID'
  | 'certificateDiamondSize'
>;

export const jewelryStockShowedColumns: (keyof GridStockJewelryColumns)[] = [
  'directIcePrice',
  'description',
  'certificateDiamondStockNumber',
  'certificateDiamondFinishCutID',
  'certificateDiamondColorID',
  'certificateDiamondClarityID',
  'certificateDiamondSize',
];

export interface GridStockJewelry extends IStockJewelry {}
export interface GridStockJewelryFilters {
  search: string;
  jewelryCategoryID: string;
  jewelrySubCategoryID: string;
  jewelryMetalColorID: string;
  jewelryMetalKaratID: string;
  diamondShapeID: string;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  view: DIAMOND_VIEWS;
  data: GridStockJewelry[];
  pagination: Pagination;
  filters: GridStockJewelryFilters;
  showedColumns: (keyof GridStockJewelryColumns)[];
  orderBy: DIAMOND_ORDER_BY;
}

interface Pagination {
  take: number;
  count: number;
  page: number;
}

export const initStateStock = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    view: DIAMOND_VIEWS.LIST,
    pagination: {
      take: 20,
      count: 0,
      page: 1,
    },
    filters: {
      search: '',
      jewelryCategoryID: '',
      jewelrySubCategoryID: '',
      jewelryMetalColorID: '',
      jewelryMetalKaratID: '',
      diamondShapeID: '',
    },
    showedColumns: [],
    orderBy: DIAMOND_ORDER_BY.PRICE_HIGH,
  };
};

const slice = createSlice({
  name: 'STOCK_JEWELRY',
  initialState: initStateStock(),
  reducers: {
    actionStockJewelriesSetPagination(state, action: PayloadAction<Partial<Pagination>>) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    actionStockJewelriesSetShowedColumns(
      state,
      action: PayloadAction<(keyof GridStockJewelryColumns)[]>,
    ) {
      state.showedColumns = Array.from(new Set([...action.payload]).keys());
    },
    actionStockJewelriesSetOrder(state, action: PayloadAction<DIAMOND_ORDER_BY>) {
      state.orderBy = action.payload;
    },
    actionStockJewelriesSetFilters(state, action: PayloadAction<Partial<DiamondFilters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionStockJewelriesClearFilters(state) {
      state.filters = initStateStock().filters;
    },
    actionStockJewelriesSetView(state, action: PayloadAction<DIAMOND_VIEWS>) {
      state.view = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionStockJewelriesGet.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(actionStockJewelriesGet.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isInit = true;
        state.data = payload.data.map((item) => ({ ...item, __checked: false }));
        state.pagination.count = payload.count;
      })
      .addCase(actionStockJewelriesGet.rejected, (state, { error }) => {
        state.isLoading = false;
        state.isInit = true;
        state.error = error;
      });

    builder.addCase(actionAccountSetCompanyID, (state) => {
      state.isInit = false;
      state.pagination.page = 1;
    });
    builder.addCase(actionAccountLogout.fulfilled, (state, action) => {
      return initStateStock();
    });

    builder.addMatcher(
      isAnyOf(
        apiStockJewelries.endpoints.deleteStockJewelry.matchPending,
        apiStockJewelries.endpoints.postStockJewelry.matchPending,
        apiStockJewelries.endpoints.patchStockJewelry.matchPending,
      ),
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        apiStockJewelries.endpoints.deleteStockJewelry.matchFulfilled,
        apiStockJewelries.endpoints.postStockJewelry.matchFulfilled,
        apiStockJewelries.endpoints.patchStockJewelry.matchFulfilled,
      ),
      (state) => {
        state.isInit = false;
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        apiStockJewelries.endpoints.deleteStockJewelry.matchRejected,
        apiStockJewelries.endpoints.postStockJewelry.matchRejected,
        apiStockJewelries.endpoints.patchStockJewelry.matchRejected,
      ),
      (state) => {
        state.isLoading = false;
      },
    );
  },
});

export const {
  actionStockJewelriesSetPagination,
  actionStockJewelriesSetShowedColumns,
  actionStockJewelriesSetFilters,
  actionStockJewelriesSetOrder,
  actionStockJewelriesClearFilters,
  actionStockJewelriesSetView,
} = slice.actions;

export const reducerStockJewelries = slice.reducer;
