import { LabelTranslateProps, TranslateMap, TranslateProps } from 'configs';
import { useAppSelector } from 'hooks/redux';
import { useCallback } from 'react';
import { Label } from 'services/labels';
import { selectLabelsMap } from 'store/labels';
import { calcTranslate } from 'utils/other';

export const getTranslateValue = (map: Record<string, Label | undefined>, key: string) => {
  const label = map[key];
  if (!label && process.env.NODE_ENV === 'production') {
    console.log(`%c "${key}" %c not found please add it to crm`, 'color: red;', 'color: black;');
  }
  return label?.title || key;
};

export const useTranslate = () => {
  const map = useAppSelector(selectLabelsMap);
  const t = useCallback(
    (key: string) => {
      return getTranslateValue(map, key);
    },
    [map],
  );

  const tp = useCallback(
    <T extends keyof TranslateMap>(...params: TranslateProps<T> | LabelTranslateProps) => {
      return params[1] ? calcTranslate(t(params[0]), params[1]) : t(params[0]);
    },
    [t],
  );
  return { t, tp };
};
