import clsx from 'clsx';
import { NativeScroll } from 'components/native-scroll';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer, Header } from './components';
import style from './index.module.scss';

export const PrivateLayout = memo(() => {
  return (
    <NativeScroll isAlwaysVisible>
      <div className={style.wrap}>
        <Header />
        <main className={clsx('container')}>
          <Outlet />
        </main>
        <Footer />
      </div>
    </NativeScroll>
  );
});
