import { MenuSelect } from 'components/menu-select';
import { useLanguages } from 'hooks';
import { useDecorator } from 'hooks/use-action-decorators';
import React from 'react';
import style from './index.module.scss';

interface Props {
  onChanged?: () => void;
}

export const HeaderLanguage: React.FC<Props> = ({ onChanged }) => {
  const {
    languageID,
    source: { data },
    onChange,
  } = useLanguages();
  return (
    <MenuSelect
      classes={{ title: style.title }}
      value={languageID}
      titleKey={'isoCode'}
      options={data}
      onChange={useDecorator(onChange, onChanged)}
    />
  );
};
