import { ServiceStockDiamondsToDiamondKeySymbols } from 'services/stock-diamond-to-diamond-key-symbols';
import { ServiceStockDiamondsToDiamondOvertones } from 'services/stock-diamond-to-diamond-overtones';
import { equals, makeFilter, mergeFilters, select } from 'utils/dynamic';
import { keepToModel, setToModel } from 'utils/other';
import {
  apiApp,
  apiRtk,
  DynamicParams,
  DynamicResult,
  RTK_TAGS,
  transformResponseDynamic,
  transformResponseDynamicItem,
  uploadFile,
  uploadImage,
} from 'utils/service';
import { API_STOCK_DIAMONDS, StockDiamond, StockDiamondNew, StockDiamondPatch } from './models';

export * from './models';

interface GetParams extends DynamicParams {
  agencyID: string;
}

class Service {
  private async updateMedia(newData: Partial<StockDiamondNew>, oldData: Partial<StockDiamondNew>) {
    return {
      mediaPicture: await uploadImage(newData.mediaPicture, oldData.mediaPicture),
      mediaReport: await uploadFile(newData.mediaReport, oldData.mediaReport),
      mediaVideo: await uploadFile(newData.mediaVideo, oldData.mediaVideo),
    };
  }

  async getAllDynamic<T extends GetParams>(params: T) {
    return apiApp.get<DynamicResult<StockDiamond, T>>(API_STOCK_DIAMONDS.GET, {
      params,
    });
  }

  async get<T extends GetParams>(id: StockDiamond['id'], params: T) {
    const _params = {
      ...params,
      filter: mergeFilters(makeFilter<StockDiamond>('id', id, equals), params.filter).join('&&'),
      take: 1,
    };
    const result = await ServiceStockDiamonds.getAllDynamic(_params);

    if (!result.data.value[0]) {
      throw new Error('record-not-found');
    }

    return { ...result, data: result.data.value[0] };
  }

  async patch(newData: StockDiamondPatch, oldData: StockDiamondPatch) {
    const mediaData = await ServiceStockDiamonds.updateMedia(newData, oldData);

    const dataToSave = {
      ...newData,
      ...mediaData,
      id: undefined,
      agencyID: undefined,
      diamondToDiamondKeySymbolIDs: undefined,
      diamondToDiamondOvertoneIDs: undefined,
    };

    const multiselectPromises: Promise<any>[] = [];

    const { diamondToDiamondKeySymbolIDs, diamondToDiamondOvertoneIDs } = newData;

    if (diamondToDiamondKeySymbolIDs) {
      multiselectPromises.push(
        ServiceStockDiamondsToDiamondKeySymbols.createBulk(
          oldData.id,
          diamondToDiamondKeySymbolIDs,
        ),
      );
    }
    if (diamondToDiamondOvertoneIDs) {
      multiselectPromises.push(
        ServiceStockDiamondsToDiamondOvertones.createBulk(oldData.id, diamondToDiamondOvertoneIDs),
      );
    }

    await Promise.all(multiselectPromises);

    return apiApp.patch(
      API_STOCK_DIAMONDS.PATCH(oldData),
      keepToModel(new StockDiamondNew(), dataToSave),
    );
  }

  async create(data: StockDiamondNew) {
    const {
      mediaPicture,
      mediaReport,
      mediaVideo,
      diamondToDiamondKeySymbolIDs,
      diamondToDiamondOvertoneIDs,
      ...restData
    } = data;
    const result = await apiApp.post<StockDiamond>(
      API_STOCK_DIAMONDS.POST,
      setToModel(new StockDiamondNew(), restData),
    );
    const { id } = result.data;

    await ServiceStockDiamonds.patch(
      {
        id,
        mediaReport,
        mediaVideo,
        mediaPicture,
        diamondToDiamondOvertoneIDs,
        diamondToDiamondKeySymbolIDs,
      },
      { id },
    );

    return result;
  }

  async delete(data: Pick<StockDiamond, 'id'>) {
    return apiApp.delete(API_STOCK_DIAMONDS.DELETE(data));
  }
}

export const ServiceStockDiamonds = new Service();

export const apiStockDiamonds = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getSourceStockDiamonds: build.query<
        Pick<
          StockDiamond,
          'id' | 'stockNumber' | 'diamondShapeTitle' | 'diamondShapeID' | 'diamondShapeIcon'
        >[],
        { agencyID: string }
      >({
        query({ agencyID }) {
          return {
            url: API_STOCK_DIAMONDS.GET,
            params: {
              agencyID,
              select: select<StockDiamond>(
                'id',
                'stockNumber',
                'diamondShapeTitle',
                'diamondShapeID',
                'diamondShapeIcon',
              ),
            },
          };
        },
        transformResponse: transformResponseDynamic,
        providesTags: [{ type: RTK_TAGS.STOCK_DIAMOND }],
      }),
      getStockDiamond: build.query<StockDiamond, { agencyID: string; diamondID: string }>({
        query({ diamondID, agencyID }) {
          return {
            url: API_STOCK_DIAMONDS.GET,
            params: {
              agencyID,
              filter: makeFilter<StockDiamond>('id', diamondID, equals),
              take: 1,
            },
          };
        },
        transformResponse: transformResponseDynamicItem,
        providesTags: (res, err, args) => [{ type: RTK_TAGS.STOCK_DIAMOND, id: args.diamondID }],
      }),
      getStockDiamondSimilar: build.query<
        StockDiamond[],
        { agencyID: string; diamondShapeID: string }
      >({
        query({ diamondShapeID, agencyID }) {
          return {
            url: API_STOCK_DIAMONDS.GET,
            params: {
              agencyID,
              filter: makeFilter<StockDiamond>('diamondShapeID', diamondShapeID, equals),
              take: 6,
            },
          };
        },
        transformResponse: transformResponseDynamic,
        providesTags: [{ type: RTK_TAGS.STOCK_DIAMOND }],
      }),
    };
  },
});
