import clsx from 'clsx';
import React, { ForwardedRef, forwardRef, HTMLProps, memo } from 'react';
import style from './index.module.scss';

interface Props extends HTMLProps<HTMLDivElement> {
  className?: string;
  isAlwaysVisible?: boolean;
}

const Component = (
  { isAlwaysVisible, className, children, onScroll, ...rest }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <div
      ref={ref}
      className={clsx(style.root, className, isAlwaysVisible && style.rootVisible)}
      {...rest}
    >
      {children}
    </div>
  );
};

export const NativeScroll = memo(forwardRef(Component)) as typeof Component;
