import { useContext, useEffect } from 'react';
import { MetaContext } from '../contexts';

const usePageMetaContext = () => {
  const dispatch = useContext(MetaContext);

  if (!dispatch) {
    throw new Error('you should use => AppMetaContext');
  }
  return dispatch;
};

interface PsePageScriptsProps {
  generalBodyScripts?: string | null | undefined;
  generalFooterScripts?: string | null | undefined;
  generalHeaderScripts?: string | null | undefined;
  googleAnalyticScripts?: string | null | undefined;
}

export const usePageScripts = (props?: PsePageScriptsProps) => {
  const dispatch = usePageMetaContext();

  const { generalHeaderScripts, generalBodyScripts, generalFooterScripts, googleAnalyticScripts } =
    props || {};

  useEffect(() => {
    dispatch({
      type: 'set',
      payload: {
        generalHeaderScripts,
        generalBodyScripts,
        generalFooterScripts,
        googleAnalyticScripts,
      },
    });
  }, [
    generalHeaderScripts,
    generalBodyScripts,
    generalFooterScripts,
    googleAnalyticScripts,
    dispatch,
  ]);
};

interface UsePageMetaProps {
  metaTitle?: string | null | undefined;
  metaDescription?: string | null | undefined;
  metaKeywords?: string | null | undefined;
}

export const usePageMeta = (props?: UsePageMetaProps) => {
  const dispatch = usePageMetaContext();
  const { metaTitle, metaDescription, metaKeywords } = props || {};

  useEffect(() => {
    dispatch({
      type: 'set',
      payload: {
        metaTitle,
        metaDescription,
        metaKeywords,
      },
    });
  }, [metaTitle, metaDescription, metaKeywords, dispatch]);
};
