import { apiApp } from 'utils/service';
import { API_STOCK_DIAMOND_TO_DIAMOND_OVERTONES } from './models';

export * from './models';

class Service {
  async createBulk(diamondID: string, diamondToDiamondOvertoneIDs: string[]) {
    return apiApp.post(API_STOCK_DIAMOND_TO_DIAMOND_OVERTONES.POST_BULK, {
      diamondID,
      diamondToDiamondOvertoneIDs,
    });
  }
}

export const ServiceStockDiamondsToDiamondOvertones = new Service();
