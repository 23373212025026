import { createAsyncThunk } from '@reduxjs/toolkit';
import { DiamondImage, ServiceDiamondImages } from 'services/diamond-images';
import { AppAsyncThunkConfig } from 'store/index';
import { decoratorIsNotNullable, dynamicNamespace, equals, mergeFilters } from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { selectDiamondImagesFilters, selectDiamondImagesPagination } from './selectors';

const dynamic = dynamicNamespace<DiamondImage>();

export const actionDiamondImagesGet = createAsyncThunk<
  { data: DiamondImage[]; count: number },
  void,
  AppAsyncThunkConfig
>(`DIAMOND_IMAGES/get`, async (_, { getState }) => {
  const { skip, take } = selectDiamondImagesPagination(getState());
  const { diamondShapeID, diamondColorID, diamondClarityID, diamondFancyColorID } =
    selectDiamondImagesFilters(getState());
  try {
    const {
      data: { value: data, count },
    } = await ServiceDiamondImages.getAllDynamic({
      count: true,
      filter: mergeFilters(
        dynamic.makeFilter('diamondShapeID', diamondShapeID, decoratorIsNotNullable(equals)),
        dynamic.makeFilter('diamondColorID', diamondColorID, decoratorIsNotNullable(equals)),
        dynamic.makeFilter('diamondClarityID', diamondClarityID, decoratorIsNotNullable(equals)),
        dynamic.makeFilter(
          'diamondFancyColorID',
          diamondFancyColorID,
          decoratorIsNotNullable(equals),
        ),
      ).join('&&'),
      skip,
      take,
    });
    return { data, count };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
