import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import clsx from 'clsx';
import { useMap } from 'hooks/use-map';
import React, { useCallback } from 'react';
import style from './index.module.scss';

interface Classes {
  root: string;
  title: string;
}

interface Option {
  id: string;
  title: string;
}
interface BaseProps<T extends Record<string, any> = Option, K extends keyof T = 'id'> {
  className?: string;
  value?: T[K] | null;
  onChange?: (v: T[K]) => void;
  options?: T[];
  isLoading?: boolean;
  classes?: Partial<Classes>;
}
type PropsValueKey<T, K extends keyof T> = T extends { id: string }
  ? BaseProps<T, K> & { valueKey?: keyof T }
  : BaseProps<T, K> & { valueKey: keyof T };

type PropsTitleKey<T, K extends keyof T> = T extends { title: string }
  ? BaseProps<T, K> & { titleKey?: keyof T }
  : BaseProps<T, K> & { titleKey: keyof T };

export type MenuSelectProps<T, K extends keyof T> = PropsValueKey<T, K> & PropsTitleKey<T, K>;
export const MenuSelect = <T extends Record<string, any> = Option, K extends keyof T = 'id'>({
  value,
  valueKey = 'id',
  titleKey = 'title',
  options = [],
  onChange,
  classes,
  isLoading,
}: MenuSelectProps<T, K>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onClickListItem = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onMenuItemClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, id: T[K]) => {
      setAnchorEl(null);
      // @ts-ignore
      onChange && onChange(id);
    },
    [onChange],
  );

  const mapOptions = useMap(options, valueKey);
  const selected = value ? mapOptions[value] : null;

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <List component={'nav'} className={style.wrap}>
        <ListItem
          button
          aria-expanded={open ? 'true' : undefined}
          onClick={onClickListItem}
          className={style.item}
        >
          <ListItemText className={clsx(style.itemTitle, classes?.title)} disableTypography>
            {selected?.[titleKey]}
          </ListItemText>
          <ListItemIcon className={clsx(style.itemIcon, open && style.itemIconOpen)}>
            <ArrowDropDownRoundedIcon />
          </ListItemIcon>
        </ListItem>
      </List>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          role: 'listbox',
        }}
      >
        {options.map((item) => (
          <MenuItem
            key={item[valueKey]}
            selected={selected?.[valueKey] === item[valueKey]}
            onClick={(event) => onMenuItemClick(event, item[valueKey])}
          >
            {item[titleKey]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
