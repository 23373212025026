import { equals, makeFilter, mergeFilters, orderBy, select } from 'utils/dynamic';
import { apiRtk, transformResponseDynamic } from 'utils/service';
import {
  API_JEWELRY_PARAMETERS,
  BaseJewelryParameter,
  JewelryCategory,
  JewelryRingSize,
  JewelrySubCategory,
} from './models';

const axiosParams = {
  params: {
    filter: mergeFilters(makeFilter<BaseJewelryParameter>('isActive', true, equals)).join('&&'),
    orderBy: orderBy('rank', 'asc'),
  },
};

export const apiJewelryParameters = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getJewelryMetalColors: build.query<Pick<BaseJewelryParameter, 'id' | 'catalogName'>[], void>({
        query: () => ({
          url: API_JEWELRY_PARAMETERS.GET_METAL_COLORS,
          params: { ...axiosParams, select: select<BaseJewelryParameter>('id', 'catalogName') },
        }),
        transformResponse: transformResponseDynamic,
      }),
      getJewelryMetalKarats: build.query<Pick<BaseJewelryParameter, 'id' | 'catalogName'>[], void>({
        query: () => ({
          url: API_JEWELRY_PARAMETERS.GET_METAL_KARATS,
          params: { ...axiosParams, select: select<BaseJewelryParameter>('id', 'catalogName') },
        }),
        transformResponse: transformResponseDynamic,
      }),
      getJewelryCategories: build.query<
        Pick<JewelryCategory, 'id' | 'catalogName' | 'icon' | 'jewelryCategoryType'>[],
        void
      >({
        query: () => ({
          url: API_JEWELRY_PARAMETERS.GET_CATEGORIES,
          params: {
            ...axiosParams,
            select: select<BaseJewelryParameter>(
              'id',
              'catalogName',
              'icon',
              'jewelryCategoryType',
            ),
          },
        }),
        transformResponse: transformResponseDynamic,
      }),
      getJewelrySubCategories: build.query<
        Pick<JewelrySubCategory, 'id' | 'catalogName' | 'jewelryCategoryID' | 'icon'>[],
        void
      >({
        query: () => ({
          url: API_JEWELRY_PARAMETERS.GET_SUB_CATEGORIES,
          params: {
            ...axiosParams,
            select: select<BaseJewelryParameter>('id', 'catalogName', 'jewelryCategoryID', 'icon'),
          },
        }),
        transformResponse: transformResponseDynamic,
      }),
      getJewelryRingSizes: build.query<JewelryRingSize[], void>({
        query: () => ({
          url: API_JEWELRY_PARAMETERS.GET_RING_SIZE,
          params: {
            ...axiosParams,
          },
        }),
        transformResponse: transformResponseDynamic,
      }),
    };
  },
});
