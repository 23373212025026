import { FullStoryProvider } from 'contexts/full-stoty';
import { useAppSelector, useLanguage, usePageMeta, usePageScripts } from 'hooks';
import React, { PropsWithChildren } from 'react';
import { apiPages } from 'services/pages';
import { selectAccountUser } from 'store/auth';

export const AppPageProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { languageID } = useLanguage();
  const { data } = apiPages.useGetAppPageQuery({ languageID });

  usePageMeta(data);
  usePageScripts(data);

  const user = useAppSelector(selectAccountUser);

  return (
    <>
      {data && data.fullStoryCode && (
        <FullStoryProvider
          code={data.fullStoryCode}
          firstName={user?.firstName}
          lastName={user?.lastName}
        />
      )}
      {children}
    </>
  );
};
