import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, ListItem } from '@mui/material';
import clsx from 'clsx';
import { AppLogo } from 'components/app-logo';
import { useOpen } from 'hooks';
import { HeaderCompany } from 'layouts/private/components/header-company';
import { HeaderLanguage } from 'layouts/private/components/header-language';
import { HeaderNavigation } from 'layouts/private/components/header-navigation';
import React from 'react';
import variables from 'styles/config.scss';
import style from './index.module.scss';

interface Props {
  className?: string;
}

export const HeaderMenuMobile: React.FC<Props> = ({ className }) => {
  const { isOpen, onClose, onOpen } = useOpen();
  return (
    <div className={clsx(style.wrap, className)}>
      <ListItem component={'button'} button onClick={onOpen}>
        <MenuIcon sx={{ color: variables.colorSecondaryLight }} />
      </ListItem>

      <Drawer open={isOpen} onClose={onClose} classes={{ paper: style.drawer }}>
        <div className={style.top}>
          <ListItem component={'button'} button onClick={onClose} sx={{ height: '100%' }}>
            <CloseIcon sx={{ color: variables.colorSecondaryLight }} />
          </ListItem>
          <AppLogo />
          <HeaderLanguage onChanged={onClose} />
        </div>
        <div className={style.middle}>
          <HeaderNavigation isVertical onChanged={onClose} />
        </div>
        <div className={style.bottom}>
          <HeaderCompany onChanged={onClose} />
        </div>
      </Drawer>
    </div>
  );
};
