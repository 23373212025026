import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import React from 'react';
import style from './index.module.scss';

interface Props {
  className?: string;
}

export const Footer: React.FC<Props> = ({ className }) => {
  const { t } = useTranslate();

  return (
    <footer className={clsx('container', style.wrap, className)}>
      <Typography color={'#999'} variant={'body2'}>
        {t('copyright')}
      </Typography>
    </footer>
  );
};
