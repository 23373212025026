import { takeEvery } from 'modules/typed-saga';
import { workerErrorNotifyThunk } from 'utils/sagas';
import {
  actionMyFavoriteAddFavorite,
  actionMyFavoriteGet,
  actionMyFavoriteRemoveFavorite,
} from './actions';

export const sagasMyFavorite = [
  takeEvery(
    [
      actionMyFavoriteGet.rejected,
      actionMyFavoriteAddFavorite.rejected,
      actionMyFavoriteRemoveFavorite.rejected,
    ],
    workerErrorNotifyThunk,
  ),
];
