import { createTypedRoute } from 'modules/typed-routes';

export const APP_ROUTES = {
  HOME: createTypedRoute('/'),
  MY_STOCK: createTypedRoute<{ previewID: string } | void>('/stock'),
  MY_STOCK_JEWELRIES: createTypedRoute<{ previewID: string } | void>('/jewelries-stock'),
  MY_STOCK_JEWELRY_ITEM: createTypedRoute<{ stockID: string | 'true' }>(
    '/jewelries-stock/:stockID',
  ),
  MY_FAVORITE: createTypedRoute<{ previewID: string } | void>('/favorite'),
  MY_STOCK_ITEM: createTypedRoute<{ stockID: string | 'true' }>('/stock/:stockID'),
  MARKETPLACE: createTypedRoute<{ previewID: string } | void>('/marketplace'),

  LOGIN: createTypedRoute<{ redirect?: string }>('/login'),
  LOGIN_CODE: createTypedRoute<{ email: string; redirect?: string }>('/login/:email'),
  LOGIN_COMPANY: createTypedRoute<{ redirect?: string }>('/login/company'),
};
