export const APP_CURRENCY = '$';
export const APP_PERCENT = '%';

export const SIZE_MIN = 0.2;
export const SIZE_MAX = 30;

export const PRICE_MIN = 100;
export const PRICE_MAX = 500_000;

export const TIME_FORMAT_DATE = 'yyyy-MM-dd';
export const TIME_FORMAT_DATE_TIME = `${TIME_FORMAT_DATE} HH:mm`;
export const FORMAT_DATE = `dd.MM.yyyy`;
export const FORMAT_MONTH_AND_YEAR_DATE = `MMM yyyy`;
export const FORMAT_TIME = `HH:mm`;
