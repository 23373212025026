import { apiRtk } from 'utils/service';
import {
  API_ANALYTICS,
  DiamondAnalyticFavorite,
  DiamondAnalyticFavoriteKpi,
  DiamondAnalyticKpi,
  DiamondAnalyticStockKpi,
} from './models';

export * from './models';

export const apiAnalytics = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getFavorites: build.query<DiamondAnalyticFavorite[], { agencyID: string }>({
        query: ({ agencyID }) => ({
          url: API_ANALYTICS.GET_FAVORITES,
          params: {
            agencyID,
          },
        }),
      }),
      getFavoritesKpi: build.query<DiamondAnalyticFavoriteKpi[], { agencyID: string }>({
        query: ({ agencyID }) => ({
          url: API_ANALYTICS.GET_FAVORITES_KPI,
          params: {
            agencyID,
          },
        }),
      }),
      getDiamondsKpi: build.query<DiamondAnalyticKpi, { agencyID: string }>({
        query: ({ agencyID }) => ({
          url: API_ANALYTICS.GET_KPI,
          params: {
            agencyID,
          },
        }),
      }),
      getDiamondsStockKpi: build.query<DiamondAnalyticStockKpi[], { agencyID: string }>({
        query: ({ agencyID }) => ({
          url: API_ANALYTICS.GET_STOCKS_KPI,
          params: {
            agencyID,
          },
        }),
      }),
    };
  },
});
