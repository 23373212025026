import { createSelector } from '@reduxjs/toolkit';
import { selectLanguageID } from 'store/labels';
import { createMap } from 'utils/other';
import { AppState } from '../index';

const selectState = (state: AppState) => state.languages;

export const selectLanguages = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectLanguageIsRtl = createSelector(
  selectLanguageID,
  selectLanguages,
  (languageID, languages) => {
    const language = languages.find(({ id }) => id === languageID);
    return Boolean(language?.isRTL);
  },
);
export const selectLanguagesMap = createSelector(selectLanguages, (data) => {
  return createMap(data, 'id') || {};
});

export const selectLanguagesState = createSelector(
  selectState,
  ({ isLoading, isInit, error, data }) => {
    return { isLoading, isInit, error, data };
  },
);
