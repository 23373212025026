import { createSelector } from '@reduxjs/toolkit';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';
import { StateDiamondMessageItem } from './slice';

const selectState = (state: AppState) => state.diamondMessages;

const selectItemID = (state: AppState, diamondID: string) => diamondID;

export const selectDiamondMessages = createSelector(
  [selectState, selectItemID],
  (state, itemID) => {
    const item = state[itemID] || new StateDiamondMessageItem();
    return { ...item, pagination: calcPaginationState(item.pagination) };
  },
);
