const API = 'DiamondParameters';

export const API_DIAMOND_PARAMETERS = {
  GET_ADVANCE_BRAND: `${API}/GetDiamondAdvanceBrandDynamic`,
  GET_GIRDLE_CONDITION: `${API}/GetDiamondGirdleConditionDynamic`,
  GET_GIRDLE_SIZE: `${API}/GetDiamondGirdleSizeDynamic`,
  GET_CULET_CONDITION: `${API}/GetDiamondCuletConditionDynamic`,
  GET_CULET_SIZE: `${API}/GetDiamondCuletSizeDynamic`,
  GET_KEY_SYMBOL: `${API}/GetDiamondKeySymbolDynamic`,
  GET_OVERTONE: `${API}/GetDiamondOvertoneDynamic`,
  GET_CLARITY: `${API}/GetDiamondClarityDynamic`,
  GET_COLORS: `${API}/GetDiamondColorDynamic`,
  GET_LAB: `${API}/GetDiamondLabDynamic`,
  GET_FANCY_COLORS: `${API}/GetDiamondFancyColorDynamic`,
  GET_FANCY_COLOR_INTENSITIES: `${API}/GetDiamondFancyColorIntensityDynamic`,
  GET_FINISH_CUTS: `${API}/GetDiamondFinishCutDynamic`,
  GET_AVAILABILITY: `${API}/GetDiamondAvailabilityDynamic`,
  GET_FINISH_POLISHES: `${API}/GetDiamondFinishPolishDynamic`,
  GET_FINISH_SYMMETRIES: `${API}/GetDiamondFinishSymmetryDynamic`,
  GET_FLUORESCENCE_INTENSITY_COLORS: `${API}/GetDiamondFluorescenceIntensityColorDynamic`,
  GET_FLUORESCENCE_INTENSITY_WEIGHTS: `${API}/GetDiamondFluorescenceIntensityWeightDynamic`,
  GET_GRADING_REPORTS: `${API}/GetDiamondGradingReportDynamic`,
  GET_INCLUSION_BLACK: `${API}/GetDiamondInclusionBlackDynamic`,
  GET_INCLUSION_EYE_CLEAN: `${API}/GetDiamondInclusionEyeCleanDynamic`,
  GET_INCLUSION_MILKY: `${API}/GetDiamondInclusionMilkyDynamic`,
  GET_INCLUSION_OPEN: `${API}/GetDiamondInclusionOpenDynamic`,
  GET_INCLUSION_WHITE: `${API}/GetDiamondInclusionWhiteDynamic`,
  GET_LOCATIONS: `${API}/GetDiamondLocationDynamic`,
  GET_MEDIA_TYPES: `${API}/GetDiamondMediaTypeDynamic`,
  GET_SELLER_RATINGS: `${API}/GetDiamondSellerRatingDynamic`,
  GET_SHADES: `${API}/GetDiamondShadeDynamic`,
  GET_SHAPES: `${API}/GetDiamondShapeDynamic`,
  GET_SIZES: `${API}/GetDiamondSizeDynamic`,
  GET_TREATMENT_TYPES: `${API}/GetDiamondTreatmentTypeDynamic`,
};

export interface BaseDiamondParameter {
  id: string;
  catalogName: string;
  rank: number;
  isActive: boolean;
}
export interface DiamondSize extends BaseDiamondParameter {
  fromSize: number;
  toSize: number;
}
export interface DiamondShape extends BaseDiamondParameter {
  icon: string;
}
