const API = 'Labels';

export const API_LABELS = {
  GET_DEFAULT_LANGUAGE: `${API}/GetDefaultLanguage`,
  GET_LABELS: `${API}/GetLabelsDynamic`,
};
export interface Label {
  languageID: string;
  labelKey: string;
  id: string;
  title: string;
}
