import { apiApp, DynamicParams, DynamicResult } from 'utils/service';
import { API_DIAMOND_IMAGES, DiamondImage } from './models';

export * from './models';

interface GetParams extends DynamicParams {
  count: boolean;
}

class Service {
  async getAllDynamic<P extends GetParams>(params: P) {
    return apiApp.get<DynamicResult<DiamondImage, P>>(API_DIAMOND_IMAGES.GET, {
      params,
    });
  }
}

export const ServiceDiamondImages = new Service();
