const API = 'Analytics';

export const API_ANALYTICS = {
  GET_KPI: `${API}/GetDiamondsKPIs`,
  GET_STOCKS_KPI: `${API}/GetDiamondInStockKPIs`,
  GET_FAVORITES: `${API}/GetDiamondInFavorites`,
  GET_FAVORITES_KPI: `${API}/GetDiamondInFavoriteKPIs`,
};

export interface DiamondAnalyticFavorite {
  id: string;
  agencyID: string;
  agencyName: string;
  agencyLogo: string;
  agencyDescription: string;
  diamondClarityID: string;
  diamondClarityTitle: string;
  diamondColorID: string;
  diamondColorTitle: string;
  diamondShapeID: string;
  diamondShapeTitle: string;
  diamondShapeIcon: string;
  entryDate: string;
  stockNumber: string;
  description: string;
  listPrice: number;
  directIcePrice: number;
  size: number;
}
export interface DiamondAnalyticFavoriteKpi {
  diamondShapeID: string;
  diamondShapeTitle: string;
  diamondShapeIcon: string;
  totalInFavorite: number;
}

export interface DiamondAnalyticKpi {
  totalDiamondInStock: number;
  totalDiamondInMarketplace: number;
  totalDiamondInMarketplaceValue: number;
  totalDiamondInStockValue: number;
}
export interface DiamondAnalyticStockKpi {
  diamondShapeID: string;
  diamondShapeTitle: string;
  diamondShapeIcon: string;
  totalInStock: 0;
}
