import { Loading } from 'components/loading';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { PropsWithChildren, useEffect } from 'react';
import { selectLabelsState } from 'store/labels';
import { actionLanguagesGet, selectLanguagesState } from 'store/languages';

export const TranslateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const stateLabels = useAppSelector(selectLabelsState);
  const stateLanguages = useAppSelector(selectLanguagesState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!stateLanguages.isInit && !stateLanguages.isLoading && !stateLanguages.error) {
      dispatch(actionLanguagesGet());
    }
  }, [stateLanguages.isInit, stateLanguages.isLoading, stateLanguages.error, dispatch]);

  const isLoading = stateLabels.isLoading || stateLanguages.isLoading;
  return (
    <>
      {stateLabels.isInit && children}
      {isLoading && <Loading />}
    </>
  );
};
