import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Badge, ListItem, Theme, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { APP_ROUTES } from 'configs';
import { useAppSelector, useTranslate } from 'hooks';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectAccountFavorites } from 'store/auth';
import variables from 'styles/config.scss';
import style from './index.module.scss';

interface Props {
  className?: string;
}

export const HeaderFavorite: React.FC<Props> = ({ className }) => {
  const { t } = useTranslate();
  const isMobile = useMediaQuery<Theme>((theme) => {
    return theme.breakpoints.down('lg');
  });
  const favorites = useAppSelector(selectAccountFavorites);
  const navigate = useNavigate();
  const goToPage = useCallback(() => {
    navigate(APP_ROUTES.MY_FAVORITE().config);
  }, [navigate]);
  return (
    <ListItem component={'button'} button className={style.wrap} onClick={goToPage}>
      <div className={clsx(style.box, className)}>
        {!isMobile && (
          <Typography component={'div'} color={variables.colorSecondaryLight}>
            {t('my-favorite')}
          </Typography>
        )}
        <Badge badgeContent={favorites} color={'primary'}>
          <FavoriteBorderIcon fontSize={'large'} sx={{ color: variables.colorSecondaryLight }} />
        </Badge>
      </div>
    </ListItem>
  );
};
