import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SIZE_MAX, SIZE_MIN } from 'configs';
import { StockDiamond } from 'services/stock-diamonds';
import { TradeDiamond } from 'services/trade-diamonds';
import { actionAccountLogout, actionAccountSetCompanyID } from 'store/auth';
import { actionMarketplaceGet } from 'store/marketplace/actions';
import { actionMyFavoriteAddFavorite, actionMyFavoriteRemoveFavorite } from 'store/my-favorite';
import { DIAMOND_ORDER_BY, DIAMOND_VIEWS } from 'store/shared';

export interface MarketplaceFilters
  extends Pick<
    StockDiamond,
    | 'diamondLocationID'
    | 'labLocation'
    | 'diamondFinishPolishID'
    | 'diamondFinishSymmetryID'
    | 'diamondFluorescenceIntensityWeightID'
    | 'diamondFluorescenceIntensityColorID'
    | 'diamondLabID'
    | 'diamondToDiamondOvertoneIDs'
    | 'diamondFancyColorIntensityID'
    | 'diamondFancyColorSecondID'
  > {
  search: string;
  sizeRange: number[];
  diamondFancyColorID: string;
  diamondClarityRanks: number[];
  diamondColorRanks: number[];
  diamondShapeIDs: string[];
  diamondFinishCutRanks: number[];
}

export const diamondTradeShowedColumnsFrozen: (keyof TradeDiamond)[] = [
  'agencyName',
  'stockNumber',
  'entryDate',
  'diamondLocationTitle',
  'diamondLabTitle',
  'diamondShapeTitle',
  'size',
  'diamondColorTitle',
  'diamondClarityTitle',
  'diamondFinishCutTitle',
  'diamondFinishPolishTitle',
  'listPrice',
];
export const diamondTradeShowedColumns: (keyof TradeDiamond)[] = [
  'directIcePrice',
  'diamondAdvanceBrandTitle',
  'diamondFancyColorSecondTitle',
  'diamondFancyColorIntensityTitle',
  'diamondFinishSymmetryTitle',
  'diamondFluorescenceIntensityColorTitle',
  'diamondFluorescenceIntensityWeightTitle',
  'diamondInclusionBlackTitle',
  'diamondInclusionEyeCleanTitle',
  'diamondInclusionMilkyTitle',
  'diamondInclusionOpenTitle',
  'diamondInclusionWhiteTitle',
  'diamondShadeTitle',
  'diamondTreatmentTypeTitle',
  'diamondAvailabilityTitle',
  'diamondGirdleConditionTitle',
  'diamondCuletConditionTitle',
  'diamondCuletSizeTitle',
  'description',
  'labLocation',
  'city',
  'measurementsLength',
  'measurementsWidth',
  'measurementsDepth',
  'depthPercentage',
  'tablePercentage',
  'crownAngle',
  'crownHeight',
  'pavillionAngle',
  'pavillionDepth',
  'laserInscription',
  'starLength',
  'reportNumber',
  'reportDate',
  'reportComment',
];

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  view: DIAMOND_VIEWS;
  data: MarketplaceDiamondItem[];
  pagination: Pagination;
  filters: MarketplaceFilters;
  showedColumns: (keyof TradeDiamond)[];
  orderBy: DIAMOND_ORDER_BY;
}

interface Pagination {
  take: number;
  count: number;
  page: number;
}

export interface MarketplaceDiamondItem extends TradeDiamond {}

export const initStateMarketplace = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    view: DIAMOND_VIEWS.LIST,
    pagination: {
      take: 20,
      count: 0,
      page: 1,
    },
    filters: {
      search: '',
      diamondColorRanks: [],
      diamondShapeIDs: [],
      diamondClarityRanks: [],
      diamondLocationID: '',
      labLocation: '',

      diamondLabID: '',
      diamondFancyColorID: '',
      diamondFinishCutRanks: [],
      diamondFluorescenceIntensityColorID: '',
      diamondFluorescenceIntensityWeightID: '',
      diamondFinishSymmetryID: '',
      diamondFinishPolishID: '',

      diamondToDiamondOvertoneIDs: [],
      diamondFancyColorIntensityID: '',
      diamondFancyColorSecondID: '',
      sizeRange: [SIZE_MIN, SIZE_MAX],
    },
    showedColumns: diamondTradeShowedColumnsFrozen,
    orderBy: DIAMOND_ORDER_BY.PRICE_HIGH,
  };
};
const slice = createSlice({
  name: 'MARKETPLACE',
  initialState: initStateMarketplace(),
  reducers: {
    actionMarketplaceSetPagination(state, action: PayloadAction<Partial<Pagination>>) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    actionMarketplaceSetShowedColumns(state, action: PayloadAction<(keyof TradeDiamond)[]>) {
      state.showedColumns = Array.from(
        new Set([...diamondTradeShowedColumnsFrozen, ...action.payload]).keys(),
      );
    },
    actionMarketplaceSetOrder(state, action: PayloadAction<DIAMOND_ORDER_BY>) {
      state.orderBy = action.payload;
    },
    actionMarketplaceSetFilters(state, action: PayloadAction<Partial<MarketplaceFilters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionMarketplaceClearFilters(state) {
      state.filters = initStateMarketplace().filters;
    },
    actionMarketplaceSetView(state, action: PayloadAction<DIAMOND_VIEWS>) {
      state.view = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionMarketplaceGet.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(actionMarketplaceGet.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isInit = true;
      state.data = payload.data;
      state.pagination.count = payload.count;
    });
    builder.addCase(actionMarketplaceGet.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isInit = true;
      state.error = error;
    });
    builder.addCase(actionMyFavoriteAddFavorite.pending, (state, { meta: { arg: diamondID } }) => {
      state.data = state.data.map((item) =>
        item.id === diamondID ? { ...item, inFavorites: true } : item,
      );
    });
    builder.addCase(actionMyFavoriteAddFavorite.rejected, (state, { meta: { arg: diamondID } }) => {
      state.data = state.data.map((item) =>
        item.id === diamondID ? { ...item, inFavorites: false } : item,
      );
    });
    builder.addCase(
      actionMyFavoriteRemoveFavorite.pending,
      (state, { meta: { arg: diamondID } }) => {
        state.data = state.data.map((item) =>
          item.id === diamondID ? { ...item, inFavorites: false } : item,
        );
      },
    );
    builder.addCase(
      actionMyFavoriteRemoveFavorite.rejected,
      (state, { meta: { arg: diamondID } }) => {
        state.data = state.data.map((item) =>
          item.id === diamondID ? { ...item, inFavorites: true } : item,
        );
      },
    );
    builder.addCase(actionAccountSetCompanyID, (state) => {
      state.isInit = false;
      state.pagination.page = 1;
    });
    builder.addCase(actionAccountLogout.fulfilled, (state, action) => {
      return initStateMarketplace();
    });
  },
});

export const {
  actionMarketplaceSetPagination,
  actionMarketplaceSetShowedColumns,
  actionMarketplaceSetFilters,
  actionMarketplaceSetOrder,
  actionMarketplaceClearFilters,
  actionMarketplaceSetView,
} = slice.actions;
export const reducerMarketplace = slice.reducer;
