import { base64ToFileStream, getRandomString, setToModel } from 'utils/other';
import { apiApp } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_MEDIA_UPLOADS, MediaUploadFile, MediaUploadHtml } from './models';

export const MEDIA_PREFIX = '@@CLOUD@@';

export * from './models';

class Service {
  async remove(filePath: string) {
    return apiApp.delete(API_MEDIA_UPLOADS.REMOVE_FILE({ filePath: filePath }));
  }

  async uploadFile(data: PatchPartial<MediaUploadFile, 'fileStreamString'>) {
    return ServiceMediaUploads.upload(API_MEDIA_UPLOADS.UPLOAD_FILE, data);
  }

  async uploadHtml(data: PatchPartial<MediaUploadHtml, 'htmlContent'>) {
    return apiApp.post<MediaUploadHtml>(API_MEDIA_UPLOADS.UPLOAD_HTML, { ...data });
  }

  async uploadImage(data: PatchPartial<MediaUploadFile, 'fileStreamString'>) {
    return ServiceMediaUploads.upload(API_MEDIA_UPLOADS.UPLOAD_IMAGE, data);
  }

  private async upload(url: string, data: PatchPartial<MediaUploadFile, 'fileStreamString'>) {
    const fileName = `${MEDIA_PREFIX}__${data.fileName ? data.fileName : getRandomString(20)}`;
    const fileStreamString = base64ToFileStream(data.fileStreamString);
    return apiApp.post<MediaUploadFile>(
      url,
      setToModel(new MediaUploadFile(), { ...data, fileName, fileStreamString }),
    );
  }
}

export const ServiceMediaUploads = new Service();
