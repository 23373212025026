import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TradeDiamond } from 'services/trade-diamonds';
import { actionAccountLogout, actionAccountSetCompanyID } from 'store/auth';
import { DiamondFilters, DIAMOND_ORDER_BY, DIAMOND_VIEWS } from 'store/shared';
import {
  actionMyFavoriteAddFavorite,
  actionMyFavoriteGet,
  actionMyFavoriteRemoveFavorite,
} from './actions';

export const diamondMyFavoriteColumnsFrozen: (keyof TradeDiamond)[] = [
  'agencyName',
  'stockNumber',
  'entryDate',
  'diamondLocationTitle',
  'diamondLabTitle',
  'diamondShapeTitle',
  'size',
  'diamondColorTitle',
  'diamondClarityTitle',
  'diamondFinishCutTitle',
  'diamondFinishPolishTitle',
  'listPrice',
];
export const diamondMyFavoriteColumns: (keyof TradeDiamond)[] = [
  'directIcePrice',
  'diamondAdvanceBrandTitle',
  'diamondFancyColorSecondTitle',
  'diamondFancyColorIntensityTitle',
  'diamondFinishSymmetryTitle',
  'diamondFluorescenceIntensityColorTitle',
  'diamondFluorescenceIntensityWeightTitle',
  'diamondInclusionBlackTitle',
  'diamondInclusionEyeCleanTitle',
  'diamondInclusionMilkyTitle',
  'diamondInclusionOpenTitle',
  'diamondInclusionWhiteTitle',
  'diamondShadeTitle',
  'diamondTreatmentTypeTitle',
  'diamondAvailabilityTitle',
  'diamondGirdleConditionTitle',
  'diamondCuletConditionTitle',
  'diamondCuletSizeTitle',
  'description',
  'labLocation',
  'city',
  'measurementsLength',
  'measurementsWidth',
  'measurementsDepth',
  'depthPercentage',
  'tablePercentage',
  'crownAngle',
  'crownHeight',
  'pavillionAngle',
  'pavillionDepth',
  'laserInscription',
  'starLength',
  'reportNumber',
  'reportDate',
  'reportComment',
];

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  view: DIAMOND_VIEWS;
  data: MyFavoriteItem[];
  pagination: Pagination;
  filters: DiamondFilters;
  showedColumns: (keyof TradeDiamond)[];
  orderBy: DIAMOND_ORDER_BY;
}

interface Pagination {
  take: number;
  count: number;
  page: number;
}

export interface MyFavoriteItem extends TradeDiamond {}

export const initStateMyFavorites = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    view: DIAMOND_VIEWS.LIST,
    pagination: {
      take: 20,
      count: 0,
      page: 1,
    },
    filters: {
      search: '',
      diamondColorIDs: [],
      diamondShapeIDs: [],
      diamondClarityIDs: [],
      diamondLocationID: '',
      labLocation: '',

      sizeFrom: '',
      sizeTo: '',

      diamondLabID: '',
      diamondFancyColorID: '',
      diamondFinishCutIDs: [],
      diamondFluorescenceIntensityColorID: '',
      diamondFluorescenceIntensityWeightID: '',
      diamondFinishSymmetryID: '',
      diamondFinishPolishID: '',

      diamondToDiamondOvertoneIDs: [],
      diamondFancyColorIntensityID: '',
      diamondFancyColorSecondID: '',
    },
    showedColumns: diamondMyFavoriteColumnsFrozen,
    orderBy: DIAMOND_ORDER_BY.PRICE_HIGH,
  };
};
const slice = createSlice({
  name: 'MY_FAVORITE',
  initialState: initStateMyFavorites(),
  reducers: {
    actionMyFavoriteSetPagination(state, action: PayloadAction<Partial<Pagination>>) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    actionMyFavoriteSetShowedColumns(state, action: PayloadAction<(keyof TradeDiamond)[]>) {
      state.showedColumns = Array.from(
        new Set([...diamondMyFavoriteColumns, ...action.payload]).keys(),
      );
    },
    actionMyFavoriteSetOrder(state, action: PayloadAction<DIAMOND_ORDER_BY>) {
      state.orderBy = action.payload;
    },
    actionMyFavoriteSetFilters(state, action: PayloadAction<Partial<DiamondFilters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionMyFavoriteClearFilters(state) {
      state.filters = initStateMyFavorites().filters;
    },
    actionMyFavoriteSetView(state, action: PayloadAction<DIAMOND_VIEWS>) {
      state.view = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionMyFavoriteGet.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(actionMyFavoriteGet.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isInit = true;
      state.data = payload.data;
      state.pagination.count = payload.count;
    });
    builder.addCase(actionMyFavoriteGet.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isInit = true;
      state.error = error;
    });

    builder.addCase(actionMyFavoriteAddFavorite.pending, (state, { meta: { arg: diamondID } }) => {
      state.data = state.data.map((item) =>
        item.id === diamondID ? { ...item, inFavorites: true } : item,
      );
    });
    builder.addCase(actionMyFavoriteAddFavorite.rejected, (state, { meta: { arg: diamondID } }) => {
      state.data = state.data.map((item) =>
        item.id === diamondID ? { ...item, inFavorites: false } : item,
      );
    });

    builder.addCase(
      actionMyFavoriteRemoveFavorite.pending,
      (state, { meta: { arg: diamondID } }) => {
        state.data = state.data.map((item) =>
          item.id === diamondID ? { ...item, inFavorites: false } : item,
        );
      },
    );
    builder.addCase(
      actionMyFavoriteRemoveFavorite.rejected,
      (state, { meta: { arg: diamondID } }) => {
        state.data = state.data.map((item) =>
          item.id === diamondID ? { ...item, inFavorites: true } : item,
        );
      },
    );

    builder.addCase(actionAccountSetCompanyID, (state) => {
      state.isInit = false;
      state.pagination.page = 1;
    });
    builder.addCase(actionAccountLogout.fulfilled, (state, action) => {
      return initStateMyFavorites();
    });

    builder.addMatcher(
      (action) => {
        return [
          actionMyFavoriteAddFavorite.fulfilled,
          actionMyFavoriteRemoveFavorite.fulfilled,
        ].some((item) => item.type === action.type);
      },
      (state) => {
        state.isInit = false;
        state.isLoading = false;
      },
    );
  },
});

export const {
  actionMyFavoriteSetPagination,
  actionMyFavoriteSetShowedColumns,
  actionMyFavoriteSetFilters,
  actionMyFavoriteSetOrder,
  actionMyFavoriteClearFilters,
  actionMyFavoriteSetView,
} = slice.actions;
export const reducerMyFavorite = slice.reducer;
