import { JEWELRY_CATEGORY_TYPE } from 'services/jewelry-parameters/models';
import { ValueFileUploader } from 'utils/file-uploader';
import * as yup from 'yup';
const API = 'StockJewelries';

export const API_STOCK_JEWELRIES = {
  GET: `${API}/GetAllStockJewelriesDynamic`,
  POST: `${API}/CreateStockJewelry`,
  PATCH: (data: Pick<StockJewelry, 'id'>) => `${API}/PatchStockJewelry/${data.id}`,
  DELETE: (data: Pick<StockJewelry, 'id'>) => `${API}/DeleteStockJewelry/${data.id}`,
};

export const schemaStockJewelry = yup.object({
  jewelryCategoryType: yup.mixed().nullable(),
  jewelryRingSizeID: yup
    .string()
    .nullable()
    .when('jewelryCategoryType', {
      is: JEWELRY_CATEGORY_TYPE.displaySize,
      then: yup.string().nullable().required('rule-required'),
    }),
  length: yup
    .string()
    .nullable()
    .when('jewelryCategoryType', {
      is: JEWELRY_CATEGORY_TYPE.displayLength,
      then: yup.string().nullable().required('rule-required'),
    }),
  jewelrySubCategoryID: yup.string().nullable().required('rule-required'),
  jewelryMetalColorID: yup.string().nullable().required('rule-required'),
  jewelryMetalKaratID: yup.string().nullable().notRequired(),
  certificateDiamondID: yup.string().nullable(),
  entryDate: yup.string().nullable().required('rule-required'),
  stockNumber: yup.string().nullable().required('rule-required'),
  title: yup.string().nullable().required('rule-required'),
  description: yup.string().nullable(),
  listPrice: yup.number().notRequired().required('rule-required'),
  directIcePrice: yup.number().notRequired().required('rule-required'),
  diamondShapeID: yup.string().nullable().notRequired(),
  diamondQuantity: yup.number().notRequired().integer('rule-number-integer'),
  diamondAvarageTotalCarat: yup.string().nullable(),
  diamondAvarageColor: yup.string().nullable(),
  diamondAvarageClarity: yup.string().nullable(),
  stockJewelryImages: yup
    .array()
    .of(
      yup.object({
        rank: yup.number(),
      }),
    )
    .min(1, 'rule-min'),
});

export class StockJewelry implements yup.InferType<typeof schemaStockJewelry> {
  id!: string;
  agencyID = '';
  jewelryRingSizeID: string | null = '';
  jewelrySubCategoryID = '';
  jewelryCategoryType = JEWELRY_CATEGORY_TYPE.displayNone;
  jewelryMetalColorID = '';
  jewelryMetalKaratID = '';
  certificateDiamondID = '';
  entryDate = new Date().toISOString();
  stockNumber = '';
  title = '';
  description = '';
  listPrice = 0;
  directIcePrice = 0;
  length = '';
  mediaVideo: string | null = '';
  mediaReport: string | null = '';
  diamondShapeID = '';
  diamondQuantity = 0;
  diamondAvarageTotalCarat = '';
  diamondAvarageColor = '';
  diamondAvarageClarity = '';
  stockJewelryImages: IStockJewelryImageCreate[] = [];
}

export interface IStockJewelry {
  agencyID: string;
  jewelryRingSizeID: string;
  jewelrySubCategoryID: string;
  jewelryMetalColorID: string;
  jewelryMetalKaratID: string;
  certificateDiamondID: string;
  entryDate: string;
  stockNumber: string;
  title: string;
  description: string;
  listPrice: number;
  directIcePrice: number;
  length: string;
  mediaPicture: string;
  mediaVideo: string;
  mediaReport: string;
  diamondShapeID: string;
  diamondQuantity: number;
  diamondAvarageTotalCarat: string;
  diamondAvarageColor: string;
  diamondAvarageClarity: string;
  id: string;
  jewelryCategoryID: string;
  jewelryCategoryTitle: string;
  jewelryCategoryIcon: string;
  jewelryCategoryType: JEWELRY_CATEGORY_TYPE;
  agencyName: string;
  agencyLogo: string;
  agencyDescription: string;
  jewelrySubCategoryTitle: string;
  jewelrySubCategoryIcon: string;
  jewelryMetalColorTitle: string;
  jewelryMetalKaratTitle: string;
  jewelryRingSizeTitle: string;
  diamondShapeTitle: string;
  certificateDiamondStockNumber: string;
  certificateDiamondMediaPicture: string;
  certificateDiamondMediaReport: string;
  certificateDiamondFinishCutID: string;
  certificateDiamondFinishCutTitle: string;
  certificateDiamondColorID: string;
  certificateDiamondColorTitle: string;
  certificateDiamondClarityID: string;
  certificateDiamondClarityTitle: string;
  certificateDiamondSize: number;
  certificateDiamondShapeID: string;
  certificateDiamondShapeTitle: string;
  stockJewelryImages: IStockJewelryImage[];
}

export interface IStockJewelryImage {
  jewelryID: string;
  picture: string;
  rank: number;
  id: string;
}
export interface IStockJewelryImageCreate {
  rank: number;
  value: ValueFileUploader;
  id: null | string;
}
