import { createSelector } from '@reduxjs/toolkit';
import { initStateStock } from 'store/stocks/slice';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';

const selectState = (state: AppState) => state.stocks;

export const selectStockStatuses = createSelector(selectState, ({ isLoading, isInit, error }) => {
  return { isLoading, isInit, error };
});
export const selectStockData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectStockOrder = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});
export const selectStockView = createSelector(selectState, ({ view }) => {
  return view;
});
export const selectStockFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectStockFiltersHas = createSelector(selectStockFilters, (filters) => {
  return JSON.stringify(initStateStock().filters) !== JSON.stringify(filters);
});
export const selectStockShowedColumns = createSelector(selectState, ({ showedColumns }) => {
  return showedColumns;
});
export const selectStockPagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
