import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponseTransformer } from 'axios';
import { ServiceMediaUploads } from 'services/media-uploads';
import { isFileLike, ValueFileUploader } from 'utils/file-uploader';

export const isServerDateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d{0,7}$/gi.test(String(value));
};

export const axiosDateTransformer: AxiosResponseTransformer = (res) => {
  try {
    return JSON.parse(res, (key, value) => {
      if (typeof value === 'string' && isServerDateString(value)) {
        const _date = Date.parse(value);
        if (_date) {
          return `${value.split('.')[0]}.000Z`;
        }
      }
      return value;
    });
  } catch (e) {
    return res;
  }
};

export const BASE_URL = process.env.REACT_APP_API as string;
export const apiApp = axios.create({
  baseURL: `${BASE_URL}/api`,
  transformResponse: [axiosDateTransformer],
});

export interface DynamicParams {
  select?: string;
  filter?: string;
  orderBy?: string;
  take?: number;
  skip?: number;
  count?: boolean;
}

export type DynamicResult<T extends any, P extends DynamicParams = {}> = P extends {
  count: boolean;
}
  ? { value: T[]; count: number }
  : { value: T[] };

export const axiosBaseGetQuery =
  (): BaseQueryFn<Pick<AxiosRequestConfig, 'params' | 'url'>, unknown, AxiosError | Error> =>
  async ({ url, params }) => {
    try {
      const result = await apiApp.get(url || '', { params });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: err,
      };
    }
  };

export enum RTK_TAGS {
  STOCK_DIAMOND = 'STOCK_DIAMOND',
  STOCK_JEWELRIES = 'STOCK_JEWELRIES',
}
export const apiRtk = createApi({
  reducerPath: 'apiRtk',
  baseQuery: axiosBaseGetQuery(),
  tagTypes: [RTK_TAGS.STOCK_DIAMOND, RTK_TAGS.STOCK_JEWELRIES],
  endpoints: () => ({}),
});

export const parseErrorData = <T = string>(error: AxiosError<T> | Partial<Error>) => {
  if (!error) {
    return new Error('error');
  }
  if ('isAxiosError' in error) {
    const errorData = error.response?.data;

    if (!errorData) {
      return new Error('error');
    }

    if (typeof errorData === 'string') {
      return new Error(errorData);
    }
    return { message: 'error', ...errorData };
  }
  return new Error(error.message);
};

export const transformResponseDynamic = <T extends { value: any[] }>(data: T) => {
  return data.value;
};
export const transformResponseDynamicItem = <T extends { value: any[] }>(data: T) => {
  const item = data.value[0];
  if (!item) {
    throw new Error('record-not-found');
  }
  return item;
};

export const calcPaginationSkip = ({ page, take }: { take: number; page: number }) => {
  return take * (page - 1);
};
export const calcPaginationState = ({
  take,
  page,
  count,
}: {
  take: number;
  count: number;
  page: number;
}) => {
  const skip = calcPaginationSkip({ take, page });
  const pages = Math.floor(count / take) + 1;
  const isLastPage = pages === page;
  const isFirstPage = page === 1;
  return {
    take,
    page,
    count,
    pages,
    skip,
    isFirstPage,
    isLastPage,
  };
};

const uploadFlowActions = async <
  N extends ValueFileUploader | undefined | null = undefined,
  O extends string | undefined | null = undefined,
>(
  newValue: N,
  oldValue: O,
  uploader: (v: Exclude<N, undefined>) => Promise<{ data: { filePath: string | null } }>,
) => {
  let result = undefined;

  // @ts-ignore
  if (oldValue === newValue) {
    return Promise.resolve(result);
  }

  if (oldValue && (newValue || newValue === '')) {
    await ServiceMediaUploads.remove(oldValue);
    result = '';
  }

  if (newValue) {
    const {
      data: { filePath },
    } = await uploader(newValue as Exclude<N, undefined>);
    result = filePath;
  }

  return Promise.resolve(result);
};

const uploadFileLike = (
  value: ValueFileUploader | null,
  uploader: (
    v: Exclude<ValueFileUploader, string | undefined | null>,
  ) => Promise<{ data: { filePath: string } }>,
) => {
  if (isFileLike(value)) {
    return uploader(value);
  }
  return Promise.resolve({ data: { filePath: value } });
};

export const uploadHtml = async (
  newValue: string | undefined,
  oldValue: string | undefined,
  fileName?: string,
) => {
  return uploadFlowActions(newValue, oldValue, async (htmlContent) => {
    return ServiceMediaUploads.uploadHtml({ htmlContent, fileName });
  });
};
export const uploadImage = async (
  newValue: ValueFileUploader | undefined | null,
  oldValue: string | undefined | null,
) => {
  return uploadFlowActions(newValue, oldValue, async (value) => {
    return uploadFileLike(value, ({ value: fileStreamString, name }) =>
      ServiceMediaUploads.uploadImage({ fileStreamString, fileName: name }),
    );
  });
};
export const uploadFile = async (
  newValue: ValueFileUploader | undefined | null,
  oldValue: string | undefined | null,
) => {
  return uploadFlowActions(newValue, oldValue, async (value) => {
    return uploadFileLike(value, ({ value: fileStreamString, name }) =>
      ServiceMediaUploads.uploadFile({ fileStreamString, fileName: name }),
    );
  });
};

export const prepareRequestData = <T extends { [x: string]: any | null } = {}>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce((acc, key) => {
    // @ts-ignore
    acc[key] = data[key] === '' && String(key).endsWith('ID') ? null : data[key];
    return acc;
  }, data);
};

export const isRejectedMutation = <T>(mutationResult: any): mutationResult is { error: T } => {
  return Boolean(mutationResult && mutationResult.error);
};
export const isFulfilledMutation = <T>(mutationResult: any): mutationResult is { data: T } => {
  return Boolean(
    mutationResult && mutationResult.hasOwnProperty && mutationResult.hasOwnProperty('data'),
  );
};

export const prepareRecords = <T extends Record<string, any>, K extends keyof T>(
  newRecords: T[],
  oldRecords: T[],
  mainField: K,
) => {
  const postItems = newRecords.filter((item) => item[mainField] === null);
  const deleteItems = [] as T[];
  const patchItems = [] as T[];

  const mapNewRecordsIds = new Map(
    newRecords.filter((item) => item[mainField]).map((item) => [item[mainField], item]),
  );
  const mapOldRecordsIds = new Map(oldRecords.map((item) => [item[mainField], item]));

  Array.from(mapNewRecordsIds.entries()).forEach(([id, item]) => {
    const isInOld = mapOldRecordsIds.get(id);
    if (!isInOld) {
      postItems.push(item);
    }
  });

  Array.from(mapOldRecordsIds.entries()).forEach(([id, item]) => {
    const isInNewItem = mapNewRecordsIds.get(id);
    if (!isInNewItem) {
      deleteItems.push(item);
    }
  });

  Array.from(mapNewRecordsIds.entries()).forEach(([id, item]) => {
    const oldItem = mapOldRecordsIds.get(id);
    if (oldItem) {
      if (JSON.stringify(oldItem) !== JSON.stringify(item)) {
        patchItems.push(item);
      }
    }
  });

  return {
    postItems: postItems.map((item) => {
      let newItem = { ...item };
      delete newItem[mainField];
      return newItem;
    }) as Omit<T, K>[],
    deleteItems: deleteItems as (Omit<T, K> & Record<K, NonNullable<T[K]>>)[],
    patchItems: patchItems as (Omit<T, K> & Record<K, NonNullable<T[K]>>)[],
  };
};
