import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider } from '@mui/material';
import { useAppSelector } from 'hooks';
import React, { memo, useEffect } from 'react';
import { selectLanguageIsRtl } from 'store/languages';
import { AppThemeOptions } from 'styles/theme';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

export const themeLtr = createTheme(AppThemeOptions);
const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [prefixer],
  prepend: true,
});

export const themeRtl = createTheme({ ...AppThemeOptions, direction: 'rtl' });
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
  prepend: true,
});

const Component = ({ children }: React.PropsWithChildren<any>) => {
  const isRtl = useAppSelector(selectLanguageIsRtl);
  useEffect(() => {
    document.getElementsByTagName('html')[0].setAttribute('dir', isRtl ? 'rtl' : 'ltr');
  }, [isRtl]);
  const theme = isRtl ? themeRtl : themeLtr;
  const cache = isRtl ? cacheRtl : cacheLtr;
  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CacheProvider>
  );
};

export const AppThemeProvider = memo(Component);
