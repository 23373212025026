import { StockDiamond } from 'services/stock-diamonds';
import { orderBy } from 'utils/dynamic';

export enum DIAMOND_ORDER_BY {
  PRICE_LOW = 1,
  PRICE_HIGH = 2,
  ENTRY_DATE_DESC = 3,
  ENTRY_DATE_ASC = 4,
}

export enum DIAMOND_VIEWS {
  LIST,
  CARDS,
}

export interface DiamondFilters
  extends Pick<
    StockDiamond,
    | 'diamondLocationID'
    | 'labLocation'
    | 'diamondFinishPolishID'
    | 'diamondFinishSymmetryID'
    | 'diamondFluorescenceIntensityWeightID'
    | 'diamondFluorescenceIntensityColorID'
    | 'diamondLabID'
    | 'diamondToDiamondOvertoneIDs'
    | 'diamondFancyColorIntensityID'
    | 'diamondFancyColorSecondID'
  > {
  search: string;
  sizeFrom: string | number;
  sizeTo: string | number;
  diamondFancyColorID: string;
  diamondClarityIDs: string[];
  diamondColorIDs: string[];
  diamondShapeIDs: string[];
  diamondFinishCutIDs: string[];
}

export const calcDiamondOrderBy = (value: DIAMOND_ORDER_BY) => {
  switch (value) {
    case DIAMOND_ORDER_BY.PRICE_HIGH:
      return orderBy('listPrice', 'desc');
    case DIAMOND_ORDER_BY.PRICE_LOW:
      return orderBy('listPrice', 'asc');
    case DIAMOND_ORDER_BY.ENTRY_DATE_ASC:
      return orderBy('entryDate', 'asc');
    case DIAMOND_ORDER_BY.ENTRY_DATE_DESC:
      return orderBy('entryDate', 'desc');
  }
};
