import { useAppSelector } from 'hooks/redux';
import { useCallback } from 'react';
import { selectAccountCurrencySymbol } from 'store/auth';
import { formatPrice } from 'utils/format';

export const useFormatPrice = () => {
  const currency = useAppSelector(selectAccountCurrencySymbol);
  return useCallback(
    (v: string | number | null | undefined) => {
      const value = formatPrice(v);
      return `${currency}${value}`;
    },
    [currency],
  );
};
