import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SIZE_MAX, SIZE_MIN } from 'configs';
import { actionAccountLogout } from 'store/auth';

interface Filters {
  diamondShapeIDs: string[];
  diamondColorRanks: number[];
  diamondFinishCutRanks: number[];
  diamondClarityRanks: number[];
  sizeRange: number[];
}

interface State {
  filters: Filters;
}

const initState = (): State => {
  return {
    filters: {
      diamondColorRanks: [],
      diamondShapeIDs: [],
      diamondClarityRanks: [],

      sizeRange: [SIZE_MIN, SIZE_MAX],

      diamondFinishCutRanks: [],
    },
  };
};
const slice = createSlice({
  name: 'DASHBOARD',
  initialState: initState(),
  reducers: {
    actionDashboardSetFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionDashboardClearFilters(state) {
      state.filters = initState().filters;
    },
    actionDashboardApply() {},
  },
  extraReducers: (builder) => {
    builder.addCase(actionAccountLogout.fulfilled, (state, action) => {
      return initState();
    });
  },
});

export const { actionDashboardSetFilters, actionDashboardClearFilters, actionDashboardApply } =
  slice.actions;
export const reducerDashboard = slice.reducer;
