const API = 'JewelryParameters';

export const API_JEWELRY_PARAMETERS = {
  GET_METAL_COLORS: `${API}/GetJewelryMetalColorDynamic`,
  GET_METAL_KARATS: `${API}/GetJewelryMetalKaratDynamic`,
  GET_CATEGORIES: `${API}/GetJewelryCategoryDynamic`,
  GET_SUB_CATEGORIES: `${API}/GetJewelrySubCategoryDynamic`,
  GET_RING_SIZE: `${API}/GetJewelryRingSizeDynamic`,
};

export enum JEWELRY_CATEGORY_TYPE {
  displaySize = 1,
  displayLength = 2,
  displayNone = 3,
}

export interface BaseJewelryParameter {
  id: string;
  catalogName: string;
  rank: number;
  isActive: boolean;
}
export interface JewelryRingSize extends BaseJewelryParameter {
  usaSize: string;
  ukSize: string;
  size: string;
}
export interface JewelryCategory extends BaseJewelryParameter {
  icon: string;
  jewelryCategoryType: JEWELRY_CATEGORY_TYPE;
}
export interface JewelrySubCategory extends BaseJewelryParameter {
  icon: string;
  jewelryCategoryID: string;
}
