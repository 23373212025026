import { ThemeOptions } from '@mui/material';
import variables from 'styles/config.scss';

const appBreakpoints = {
  xs: Number(variables.xs),
  sm: Number(variables.sm),
  md: Number(variables.md),
  lg: Number(variables.lg),
  xl: Number(variables.xl),
  xxl: Number(variables.xxl),
};

export const AppThemeOptions: ThemeOptions = {
  breakpoints: {
    values: appBreakpoints,
  },
  typography: {
    htmlFontSize: 10,
    fontSize: 14,
    fontFamily: 'Arial, Tahoma, Geneva, sans-serif',
    h1: {
      fontSize: '4.8rem',
    },
    h2: {
      fontSize: '4rem',
    },
    h3: {
      fontSize: '3.2rem',
    },
    h4: {
      fontSize: '2.8rem',
    },
    h5: {
      fontSize: '1.8rem',
    },
    h6: {
      fontSize: '1.6rem',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontSize: '2rem',
    },
    subtitle2: {
      fontSize: '1.6rem',
    },
    body1: {
      fontSize: '1.4rem',
    },
    body2: {
      fontSize: '1.2rem',
    },
    button: {
      fontSize: '1.4rem',
      fontWeight: '600',
    },
    caption: {
      fontSize: '1.4rem',
    },
    overline: {
      fontSize: '1.2rem',
    },
  },

  palette: {
    primary: {
      main: variables.colorPrimary,
      dark: variables.colorSecondary,
      contrastText: '#fff',
    },
    secondary: {
      main: variables.colorSecondary,
      dark: variables.colorPrimary,
      contrastText: '#fff',
    },

    tonalOffset: 0.05,
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        containedPrimary: {
          borderRadius: '.4rem',
        },
        root: {
          fontSize: '1.4rem',
          lineHeight: '1.429',
          fontWeight: 'bold',
        },
        sizeLarge: {
          minHeight: '4.8rem',
        },
        sizeMedium: {
          minHeight: '3.6rem',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        color: 'secondary',
        size: 'small',
        autoComplete: 'off',
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&::before': {
            borderBottomColor: variables.colorGrey,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        notchedOutline: {
          borderColor: '#E9EEF0',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: variables.colorSecondaryLight,
        },
        shrink: {
          color: variables.colorSecondary,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          marginTop: '0',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontSize: '1.4rem',
        },
        clearIndicator: {
          color: variables.colorSecondaryLight,
        },
        popupIndicator: {
          color: variables.colorSecondaryLight,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: '1rem',
          fontWeight: 'bold',
          border: '1px solid #fff',
          height: '1.8rem',
          padding: '0 0.5rem',
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '2.4rem',
        },
        fontSizeSmall: {
          fontSize: '1.5rem',
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          height: '3.6rem',
          minWidth: '3.6rem',
          fontSize: '1.4rem',
          fontWeight: 'bold',
          margin: '0',
          color: variables.colorSecondaryLight,
        },
        icon: {
          fontSize: '2.2rem',
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: variables.boxShadowDarkBlue,
        },
      },
    },

    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          color: variables.colorSecondaryLightest,
          padding: '0.6rem',
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          padding: '0.6rem',
        },
        root: {
          '&.Mui-disabled': {
            color: variables.colorGrey,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '1.3576em',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 2rem 2rem',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: variables.boxShadowDarkBlue,
          borderRadius: '0',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: '4px',
          padding: '2.2rem 0',
          marginBottom: 0,
        },
        track: {
          border: 'none',
        },
        thumb: {
          height: '1.6rem',
          width: '1.6rem',
        },
        rail: {
          height: '2px',
          opacity: 1,
        },
        mark: {
          width: '1px',
          height: '4px',
          background: '#fff',
        },
        markLabel: {
          fontSize: '1.2rem',
          top: '-.4rem',
          '@media (pointer: coarse)': {
            top: '-.4rem',
          },
        },
        colorPrimary: {
          '.MuiSlider-markLabel': {
            color: variables.colorPrimary,
          },
          '.MuiSlider-rail': {
            backgroundColor: variables.colorGrey,
          },
          '.MuiSlider-track': {
            backgroundColor: variables.colorSecondary,
          },
        },
        colorSecondary: {
          '.MuiSlider-markLabel': {
            color: variables.colorSecondary,
          },
          '.MuiSlider-rail': {
            backgroundColor: variables.colorGrey,
          },
          '.MuiSlider-track': {
            backgroundColor: variables.colorPrimary,
          },
        },
      },
    },
  },
};
