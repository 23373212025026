import { createAsyncThunk } from '@reduxjs/toolkit';
import { IStockJewelry, ServiceStockJewelries } from 'services/stock-jawelries';
import { selectAccountUserCompanyID } from 'store/auth';
import { AppAsyncThunkConfig } from 'store/index';
import { calcDiamondOrderBy } from 'store/shared';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import {
  selectStockJewelriesFilters,
  selectStockJewelriesOrder,
  selectStockJewelriesPagination,
} from './selectors';

const dynamic = dynamicNamespace<IStockJewelry>();

export const actionStockJewelriesGet = createAsyncThunk<
  { data: IStockJewelry[]; count: number },
  void,
  AppAsyncThunkConfig
>(`STOCKS_JEWELRIES/get`, async (_, { getState }) => {
  const agencyID = selectAccountUserCompanyID(getState());

  if (!agencyID) {
    throw new Error('Unexpected behaviour');
  }

  const { skip, take } = selectStockJewelriesPagination(getState());
  const order = selectStockJewelriesOrder(getState());
  const {
    search,
    jewelryCategoryID,
    jewelrySubCategoryID,
    jewelryMetalColorID,
    jewelryMetalKaratID,
    diamondShapeID,
  } = selectStockJewelriesFilters(getState());

  const params = {
    agencyID,
    count: true,
    skip,
    take,
    filter: mergeFilters(
      dynamic.makeFilter(['stockNumber', 'title', 'description'], search, contains),
      dynamic.makeFilter('jewelryCategoryID', jewelryCategoryID, decoratorIsNotNullable(equals)),
      dynamic.makeFilter(
        'jewelrySubCategoryID',
        jewelrySubCategoryID,
        decoratorIsNotNullable(equals),
      ),
      dynamic.makeFilter(
        'jewelryMetalColorID',
        jewelryMetalColorID,
        decoratorIsNotNullable(equals),
      ),
      dynamic.makeFilter(
        'jewelryMetalKaratID',
        jewelryMetalKaratID,
        decoratorIsNotNullable(equals),
      ),

      dynamic.makeFilter(
        ['diamondShapeID', 'certificateDiamondShapeID'],
        diamondShapeID,
        decoratorIsNotNullable(equals),
      ),
    ).join('&&'),
    orderBy: calcDiamondOrderBy(order),
  };
  try {
    const {
      data: { value: data, count },
    } = await ServiceStockJewelries.getAllDynamic<IStockJewelry, typeof params>(params);
    return { data, count };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
