const API = 'DiamondImages';

export const API_DIAMOND_IMAGES = {
  GET: `${API}/GetAllDiamondImagesDynamic`,
};
export interface DiamondImage {
  id: string;
  picture: string;
  diamondShapeID: string;
  diamondShapeTitle: string;
  diamondClarityID: string;
  diamondClarityTitle: string;
  diamondColorID: string;
  diamondColorTitle: string;
  diamondFancyColorID: string;
  diamondFancyColorTitle: string;
}
