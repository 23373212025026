import React, { PropsWithChildren, useEffect, useState } from 'react';
import { getAuthTokens } from 'utils/cookies';
import { apiApp } from 'utils/service';

let isRefreshing = false;
let refreshingCall: Promise<any>;

type SuccessArg = { token: string; refreshToken: string; expires: string };
interface Props {
  onRefresh: () => Promise<{
    token: string;
    refreshToken: string;
    expires: string;
  }>;
  onSuccess: (p: SuccessArg) => void;
  onError: (e: Error) => void;
}

export const AxiosInterceptorsRefreshToken: React.FC<PropsWithChildren<Props>> = ({
  children,
  onRefresh,
  onSuccess,
  onError,
}) => {
  const [canRender, setCanRender] = useState(false);
  useEffect(() => {
    setCanRender(true);
    const ID = apiApp.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response ? error.response.status : null;

        const { token, refreshToken } = getAuthTokens();

        if (status !== 401 || !(token && refreshToken)) {
          return Promise.reject(error);
        }

        if (isRefreshing) {
          return refreshingCall.then(() => {
            return apiApp(error.config);
          });
        }
        isRefreshing = true;
        refreshingCall = onRefresh()
          .then((res) => {
            isRefreshing = false;
            const { token, refreshToken, expires } = res;
            onSuccess({ token, refreshToken, expires });
            return apiApp(error.config);
          })
          .catch((err) => {
            const errorStatus = err.response ? err.response.status : null;
            isRefreshing = false;
            if (errorStatus === 401) {
              onError(err);
            }
            return Promise.reject(err);
          });
        return refreshingCall;
      },
    );

    return () => {
      apiApp.interceptors.response.eject(ID);
    };
  }, [onRefresh, onSuccess, onError]);

  return <>{canRender ? children : null}</>;
};
