import { equals, makeFilter } from 'utils/dynamic';
import {
  apiApp,
  apiRtk,
  DynamicParams,
  DynamicResult,
  RTK_TAGS,
  transformResponseDynamic,
  transformResponseDynamicItem,
} from 'utils/service';
import { API_TRADE_DIAMONDS, TradeDiamond } from './models';

export * from './models';

interface GetParams extends DynamicParams {
  agencyID: string;
}

interface FavoriteProps {
  diamondID: string;
  agencyID: string;
  remarks?: string;
}

class Service {
  async getAllDynamic<T extends GetParams>(params: T) {
    return apiApp.get<DynamicResult<TradeDiamond, T>>(API_TRADE_DIAMONDS.GET, {
      params,
    });
  }
  async addToFavorite(data: FavoriteProps) {
    return apiApp.post(API_TRADE_DIAMONDS.ADD_TO_FAVORITE, data);
  }
  async removeFromFavorite(params: FavoriteProps) {
    const { diamondID: id, agencyID } = params;
    return apiApp.delete(API_TRADE_DIAMONDS.DELETE_FROM_FAVORITE({ id, agencyID }));
  }
}

export const ServiceTradeDiamonds = new Service();

export const apiTradeDiamonds = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getTradeDiamond: build.query<TradeDiamond, { agencyID: string; diamondID: string }>({
        query({ diamondID, agencyID }) {
          return {
            url: API_TRADE_DIAMONDS.GET,
            params: {
              agencyID,
              filter: makeFilter<TradeDiamond>('id', diamondID, equals),
              take: 1,
            },
          };
        },
        transformResponse: transformResponseDynamicItem,
        providesTags: (res, err, args) => [{ type: RTK_TAGS.STOCK_DIAMOND, id: args.diamondID }],
      }),
      getTradeDiamondSimilar: build.query<
        TradeDiamond[],
        { agencyID: string; diamondShapeID: string }
      >({
        query({ agencyID, diamondShapeID }) {
          return {
            url: API_TRADE_DIAMONDS.GET,
            params: {
              agencyID,
              filter: makeFilter<TradeDiamond>('diamondShapeID', diamondShapeID, equals),
              take: 6,
            },
          };
        },
        transformResponse: transformResponseDynamic,
        providesTags: [{ type: RTK_TAGS.STOCK_DIAMOND }],
      }),
    };
  },
});
