import React, { PropsWithChildren, useEffect } from 'react';
import FullStory, { identify, setUserVars } from 'react-fullstory';

interface Props {
  code: string;
  appUserID?: string;
  firstName?: string;
  lastName?: string;
}
export const FullStoryProvider: React.FC<PropsWithChildren<Props>> = ({
  code,
  children,
  appUserID,
  firstName,
  lastName,
}) => {
  useEffect(() => {
    if (appUserID) {
      identify(appUserID);
    } else {
      identify(false);
    }
  }, [appUserID]);
  useEffect(() => {
    if (firstName && lastName) {
      setUserVars({
        displayName: [firstName, lastName].filter((v) => !!v).join(' '),
        firstName,
        lastName,
      });
    }
  }, [firstName, lastName]);

  return (
    <>
      <FullStory org={code} />
      {children}
    </>
  );
};
