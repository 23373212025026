import { createAsyncThunk } from '@reduxjs/toolkit';
import { Language, ServiceParams } from 'services/parameters';
import { AppAsyncThunkConfig } from 'store/index';
import { actionLabelsSetLanguage, selectLanguageID } from 'store/labels';
import { parseErrorData } from 'utils/service';

export const actionLanguagesGet = createAsyncThunk<{ data: Language[] }, void, AppAsyncThunkConfig>(
  `Languages/actionLanguagesGet`,
  async (_, { dispatch, getState }) => {
    try {
      const {
        data: { value },
      } = await ServiceParams.getLanguages();
      const languageID = selectLanguageID(getState());
      dispatch(actionLabelsSetLanguage({ languageID }));
      return { data: value };
    } catch (e: any) {
      dispatch(actionLabelsSetLanguage({ languageID: null }));
      throw parseErrorData(e);
    }
  },
);
