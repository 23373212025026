import { createSelector } from '@reduxjs/toolkit';
import { initStateStock } from 'store/stocks/slice';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';

const selectState = (state: AppState) => state.stockJewelries;

export const selectStockJewelriesStatuses = createSelector(
  selectState,
  ({ isLoading, isInit, error }) => {
    return { isLoading, isInit, error };
  },
);
export const selectStockJewelriesData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectStockJewelriesOrder = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});
export const selectStockJewelriesView = createSelector(selectState, ({ view }) => {
  return view;
});
export const selectStockJewelriesFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectStockJewelriesFiltersHas = createSelector(
  selectStockJewelriesFilters,
  (filters) => {
    return JSON.stringify(initStateStock().filters) !== JSON.stringify(filters);
  },
);
export const selectStockJewelriesShowedColumns = createSelector(
  selectState,
  ({ showedColumns }) => {
    return showedColumns;
  },
);
export const selectStockJewelriesPagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
